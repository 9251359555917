import React, { Component } from "react";
import { View, StyleSheet } from "react-native";
import { Button, Input, Text } from "react-native-elements";
import Select from "react-select";

import firebase from "../firebase";
import { SUPPORTED_COINS, SOCIAL_MEDIA_SITES } from "../constants";
import { COLOR, generalText } from "../cssConstants";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";

const verifyCoin = firebase.functions().httpsCallable("verifyCoin");

const specialStyles = {
  control: (base) => ({
    ...base,
    fontFamily: generalText,
    transform: "translateZ(-1)",
  }),
  input: (base) => ({
    ...base,
    fontFamily: generalText,
  }),
  menu: (base) => ({
    ...base,
    fontFamily: generalText,
  }),
};

class AddInput extends Component<
  {
    loggedInUser: firebase.User;
    version: "address" | "social";
    getAddresses?: () => {};
    hideSocial?: () => {};
    showSocial?: () => {};
    getSocialMediaSites?: () => {};
  },
  {
    selectInput: string;
    textInput: string;
    customInput: string;
    showCustomInput: boolean;
    submitted: boolean;
  }
> {
  state = {
    selectInput: "",
    textInput: "",
    customInput: "",
    submitted: false,
    showCustomInput: false,
  };

  _submit = async () => {
    this.setState({ submitted: true });
    if (!this.state.submitted) {
      switch (this.props.version) {
        case "address": {
          this._addAddress();
          break;
        }
        case "social": {
          this._addSocial();
          break;
        }
      }
    }
  };

  _addSocial = async () => {
    const uid = this.props.loggedInUser?.uid;
    const loggedInAs = this.props.loggedInUser?.displayName;

    let { selectInput, textInput, showCustomInput, customInput } = this.state;
    const social = showCustomInput ? customInput : selectInput;

    if (!selectInput || !textInput) {
      ToastsStore.error(
        "Enter both a url and category for your social media link!"
      );
      this.setState({ submitted: false });
      return;
    }

    if (textInput.indexOf("http://") < 0 && textInput.indexOf("https://") < 0) {
      ToastsStore.error("Please enter the entire URL");
      this.setState({
        submitted: false,
      });
      return;
      //textInput = `https://${textInput}`;
    }
    try {
      const userRef = firebase.firestore().collection("user").doc(loggedInAs);
      await firebase.firestore().runTransaction((transaction) => {
        return transaction.get(userRef).then((userDoc) => {
          if (!userDoc.exists) {
            throw "Document does not exist!";
          }
          const oldSocial = userDoc.data().socialMediaSites ?? [];
          const newSocial = oldSocial.concat([{ social, link: textInput }]);
          transaction.update(userRef, {
            uid,
            socialMediaSites: newSocial,
          });
        });
      });
      await this.props.getSocialMediaSites();
    } catch (err) {
      console.log("failed!", err);
    }
    this.setState({ textInput: "", submitted: false });
  };
  _addAddress = async () => {
    const uid = this.props.loggedInUser?.uid;
    const loggedInAs = this.props.loggedInUser?.displayName;

    const { selectInput, textInput, showCustomInput, customInput } = this.state;
    const data = {
      currency: showCustomInput ? customInput : selectInput,
      address: textInput,
    };

    let validAddress = false;
    if (!showCustomInput) {
      validAddress = (await verifyCoin(data)).data.validAddress;
    }

    if (validAddress || this.state.showCustomInput) {
      //auto pass custom coins
      try {
        const userRef = firebase.firestore().collection("user").doc(loggedInAs);
        await firebase.firestore().runTransaction((transaction) => {
          return transaction.get(userRef).then((userDoc) => {
            if (!userDoc.exists) {
              throw "Document does not exist!";
            }
            const oldAddresses = userDoc.data().addresses
              ? userDoc.data().addresses
              : [];
            const newAddresses = oldAddresses.concat([data]);
            transaction.update(userRef, {
              uid,
              addresses: newAddresses,
            });
          });
        });
        await this.props.getAddresses();
      } catch (err) {
        console.log("failed!", err);
      }
      this.setState({ textInput: "" });
    } else {
      ToastsStore.error("Invalid address");
    }
    this.setState({ submitted: false });
  };

  _onSelect = async (selectedOption) => {
    this.setState({
      selectInput: selectedOption.value,
      showCustomInput: selectedOption.value === "custom",
    });
  };
  render() {
    const { textInput, showCustomInput, customInput } = this.state;
    let formattedSocial = Object.keys(SOCIAL_MEDIA_SITES).map((x) => {
      return { value: x, label: SOCIAL_MEDIA_SITES[x] };
    });
    formattedSocial.push({
      value: "custom",
      label: "Custom",
    });
    let formattedCoins = [...SUPPORTED_COINS];
    formattedCoins.push({
      value: "custom",
      label: "Custom",
    });
    const OPTIONS = {
      address: formattedCoins,
      social: formattedSocial,
    }[this.props.version];
    const SELECT_PLACE_HOLDER = {
      address: "Currency",
      social: "Social Media Site",
    }[this.props.version];
    const INPUT_PLACE_HOLDER = {
      address: "Address",
      social: "Link",
    }[this.props.version];
    return (
      <View>
        <ToastsContainer
          position={ToastsContainerPosition.BOTTOM_RIGHT}
          store={ToastsStore}
        />
        <Select
          styles={specialStyles}
          placeholder={SELECT_PLACE_HOLDER}
          options={OPTIONS}
          onChange={this._onSelect}
          onMenuOpen={() => {
            if (this.props.version === "address") {
              this.props.hideSocial();
            }
          }}
          onMenuClose={() => {
            if (this.props.version === "address") {
              this.props.showSocial();
            }
          }}
        />
        {showCustomInput && (
          <Input
            value={customInput}
            onChange={(e) => this.setState({ customInput: e.target.value })}
            placeholder={"Custom Label"}
            inputStyle={{ fontFamily: generalText }}
          />
        )}
        <Input
          value={textInput}
          onChange={(e) => this.setState({ textInput: e.target.value })}
          placeholder={INPUT_PLACE_HOLDER}
          inputStyle={{ fontFamily: generalText }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this._submit();
            }
          }}
        />
        <Button
          title="Save"
          onPress={this._submit.bind(this)}
          type="clear"
          titleStyle={styles.button}
          loading={this.state.submitted}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    color: COLOR.green,
    alignSelf: "stretch",
    fontFamily: generalText,
  },
});
export default AddInput;

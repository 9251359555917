import React, { Component } from "react";
import { StyleSheet, Text, View, Animated } from "react-native";
import * as Linking from "expo-linking";

import { COLOR, generalText } from "../cssConstants";

const TEXT_SIZE = 24;
const BORDER_WIDTH = 1;
const PADDING = 10;
const CONTAINER_HEIGHT = 153;
class Dropdown extends Component<
  { expanded: boolean; loggedInUser: firebase.User },
  {
    containerAnimation: Animated.Value;
    textAnimation: Animated.Value;
    borderAnimation: Animated.Value;
    paddingAnimation: Animated.Value;
    expanded: boolean;
  }
> {
  state = {
    containerAnimation: new Animated.Value(0),
    textAnimation: new Animated.Value(0),
    borderAnimation: new Animated.Value(0),
    paddingAnimation: new Animated.Value(0),
    expanded: false,
  };

  _transition = () => {
    const initialHeight = this.props.expanded ? CONTAINER_HEIGHT : 0;
    const initialTextSize = this.props.expanded ? TEXT_SIZE : 0;
    const initialBorderWidth = this.props.expanded ? BORDER_WIDTH : 0;
    const initialPadding = this.props.expanded ? PADDING : 0;

    const finalHeight = !this.props.expanded ? CONTAINER_HEIGHT : 0;
    const finalTextSize = !this.props.expanded ? TEXT_SIZE : 0;
    const finalBorderWidth = !this.props.expanded ? BORDER_WIDTH : 0;
    const finalPadding = !this.props.expanded ? PADDING : 0;

    this.state.containerAnimation.setValue(initialHeight);
    this.state.textAnimation.setValue(initialTextSize);
    this.state.borderAnimation.setValue(initialBorderWidth);
    this.state.paddingAnimation.setValue(initialPadding);

    Animated.parallel([
      Animated.spring(this.state.containerAnimation, {
        toValue: finalHeight,
        useNativeDriver: false,
      }),
      Animated.spring(this.state.textAnimation, {
        toValue: finalTextSize,
        useNativeDriver: false,
      }),
      Animated.spring(this.state.borderAnimation, {
        toValue: finalBorderWidth,
        useNativeDriver: false,
      }),
      Animated.spring(this.state.paddingAnimation, {
        toValue: finalPadding,
        useNativeDriver: false,
      }),
    ]).start();
  };

  componentDidUpdate() {
    this._transition();
  }

  componentDidMount() {
    if (this.props.expanded == this.state.expanded) {
      this._transition();
    }
  }

  render() {
    return (
      <Animated.View
        style={[styles.container, { height: this.state.containerAnimation }]}
      >
        <Animated.Text
          style={[
            styles.item,
            {
              fontSize: this.state.textAnimation,
              borderBottomWidth: this.state.borderAnimation,
              paddingVertical: this.state.paddingAnimation,
            },
          ]}
          onPress={() => {
            Linking.openURL(`/${this.props.loggedInUser.displayName}`);
          }}
        >
          Profile
        </Animated.Text>
        <Animated.Text
          style={[
            styles.item,
            {
              fontSize: this.state.textAnimation,
              borderBottomWidth: this.state.borderAnimation,
              paddingVertical: this.state.paddingAnimation,
            },
          ]}
          onPress={() => {
            Linking.openURL("/a/settings");
          }}
        >
          Settings
        </Animated.Text>
        <Animated.Text
          style={[
            styles.item,
            {
              fontSize: this.state.textAnimation,
              borderBottomWidth: this.state.borderAnimation,
              paddingVertical: this.state.paddingAnimation,
            },
          ]}
          onPress={() => {
            Linking.openURL("/a/faq");
          }}
        >
          FAQ
        </Animated.Text>
      </Animated.View>
    );
  }
}

const styles = StyleSheet.create({
  container: {},
  item: {
    textAlign: "center",
    borderBottomColor: COLOR.lightGrey,
    color: COLOR.green,
    alignSelf: "stretch",
    backgroundColor: "#F8F8F8",
    fontFamily: generalText,
  },
});
export default Dropdown;

import React, { Component } from "react";
import PacmanLoader from "react-spinners/PacmanLoader";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import { View, Text, TouchableOpacity } from "react-native";
import { Octicons } from "@expo/vector-icons";
import { COLOR, generalText } from "../cssConstants";
import { Motion, spring } from "react-motion";
import MessageList from "./MessageList";
import UserBio from "./UserBio";
import User from "./User";
import firebase from "../firebase";
import ProfilePic from "./ProfilePic";
import { MAX_SITE_WIDTH } from "../constants";
import { RouteChildrenProps } from "react-router-dom";
import { withLocation } from "../util"; // TODO Is there an existing file for utils?

class UserTabs extends Component<
  RouteChildrenProps & { loggedInUser: null | firebase.User },
  { pageOwner: string | null; userData: any; muted: boolean }
> {
  state = {
    pageOwner: null,
    userData: null,
    muted: true,
  };

  async getUserData() {
    const pageOwner =
      (this.props.location.pathname.match(/\//g) || []).length === 1
        ? this.props.location.pathname.replace("/", "")
        : null;

    if (!pageOwner) {
      return;
    }
    const userDoc = await firebase
      .firestore()
      .collection("user")
      .doc(pageOwner.toLowerCase())
      .get();
    const userData = userDoc.data();
    if (!userData) {
      window.open("/a/FourZeroFour", "_self");
    }
    this.setState({ pageOwner, userData });
  }

  componentDidMount() {
    this.getUserData();
  }
  render() {
    let tab;
    switch (window.location.hash) {
      case "#links": {
        tab = 1;
        break;
      }
      case "#messages": {
        tab = 2;
        break;
      }
      default:
        tab = 1;
    }

    const { pageOwner, userData } = this.state;
    const smallWindow = window.innerWidth < 450;
    const upperpicContainer = smallWindow
      ? { alignItems: "center" }
      : styles.picBioContainer;
    const isOwner = pageOwner === this.props.loggedInUser?.displayName;
    return (
      <View style={styles.fullPageContainer}>
        {pageOwner && (
          <View>
            <View style={upperpicContainer}>
              <ProfilePic
                isOwner={isOwner}
                pageOwner={pageOwner}
                userData={userData}
              />
              {!smallWindow && (
                <UserBio
                  isOwner={isOwner}
                  pageOwner={pageOwner}
                  userData={userData}
                  smallWindow={smallWindow}
                />
              )}
            </View>
            {smallWindow && (
              <View>
                <UserBio
                  style={{ alignSelf: "center" }}
                  isOwner={isOwner}
                  pageOwner={pageOwner}
                  userData={userData}
                  smallWindow={smallWindow}
                />
              </View>
            )}
          </View>
        )}
        <View>
          <Tabs
            defaultTab={`${tab}`}
            onChange={(tabId) => {
              window.history.pushState(
                null,
                null,
                `#${parseInt(tabId) === 1 ? "links" : "messages"}`
              );
              this.setState({}); // trigger rerender
            }}
          >
            <TabList style={styles.tabs}>
              <Motion
                defaultStyle={{ opacity: 0.2 }}
                style={{ opacity: spring(tab === 1 ? 1 : 0.2) }}
              >
                {(value) => (
                  <Tab
                    tabFor="1"
                    style={{ ...styles.tab, opacity: value.opacity }}
                  >
                    <Text style={{ fontFamily: generalText }}>Links</Text>
                  </Tab>
                )}
              </Motion>
              <Motion
                defaultStyle={{ opacity: 0.2 }}
                style={{ opacity: spring(tab === 2 ? 1 : 0.2) }}
              >
                {(value) => (
                  <Tab
                    tabFor="2"
                    style={{
                      ...styles.tab,
                      opacity: value.opacity,
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{ fontFamily: generalText, marginLeft: "auto" }}
                      >
                        Messages
                      </Text>
                      <TouchableOpacity
                        onPress={() => {
                          this.setState({ muted: !this.state.muted });
                        }}
                        style={{ marginLeft: "auto" }}
                      >
                        {this.state.muted ? (
                          <Octicons name="mute" size={24} color={COLOR.green} />
                        ) : (
                          <Octicons
                            name="unmute"
                            size={24}
                            color={COLOR.green}
                          />
                        )}
                      </TouchableOpacity>
                    </View>
                  </Tab>
                )}
              </Motion>

              <Motion
                defaultStyle={{ left: 0 }}
                style={{ left: spring(50 * (tab - 1)) }}
              >
                {(value) => (
                  <View
                    style={{ ...styles.underline, left: `${value.left}%` }}
                  />
                )}
              </Motion>
            </TabList>

            <TabPanel tabId="1">
              <View style={{ width: "100%" }}>
                {userData ? (
                  <User
                    {...this.props}
                    userData={userData}
                    loggedInUser={this.props.loggedInUser}
                  />
                ) : (
                  <View style={styles.loader}>
                    <PacmanLoader color={COLOR.green} loading={true} />
                  </View>
                )}
              </View>
            </TabPanel>
            <TabPanel tabId="2">
              <View style={{ width: "100%" }}>
                <MessageList
                  filterByRecipient={pageOwner}
                  muted={this.state.muted}
                />
              </View>
            </TabPanel>
          </Tabs>
        </View>
      </View>
    );
  }
}
const styles = {
  tabs: {
    flex: 1,
    alignitems: "center",
    justifycontent: "center",
    padding: 10,
  },
  tab: {
    width: "50%",
    background: COLOR.backgroundGreen,
    opacity: 0.3,
    padding: 10,
    border: "none",
  },
  underline: {
    position: "absolute",
    height: 2,
    backgroundColor: COLOR.green,
    marginTop: -2,
    width: "40%",
    marginHorizontal: "5%",
  },
  picBioContainer: {
    alignItems: "flex-start",
    flexDirection: "row",
    display: "flex",
    marginHorizontal: "15%",
    marginBottom: "20px",
    marginTop: "30px",
  },
  fullPageContainer: {
    margin: "auto",
    width: "100%",
    maxWidth: `${MAX_SITE_WIDTH}px`,
  },
  loader: {
    alignSelf: "center",
    justifyContent: "center",
    //width: '80%',
  },
};

export default withLocation(UserTabs);

import React, { Component } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Icon } from "react-native-elements";
import GridLoader from "react-spinners/GridLoader";

import { COLOR } from "../cssConstants";
import firebase from "../firebase";

import AddressModal from "./Modal";

class Address extends Component<
  {
    isOwner: boolean;
    loggedInUser: firebase.User;
    address: { item: { currency: string; address: string } };
    getAddresses: () => {};
  },
  { submitted: boolean }
> {
  state = {
    submitted: false,
  };
  constructor(props) {
    super(props);
    this.deleteAddress = this.deleteAddress.bind(this);
    this.moveAddress = this.moveAddress.bind(this);
  }

  async deleteAddress() {
    if (this.state.submitted) {
      return;
    }

    const uid = this.props.loggedInUser?.uid;
    const loggedInAs = this.props.loggedInUser?.displayName;
    const toDelete = this.props.address.item;

    try {
      const userRef = firebase.firestore().collection("user").doc(loggedInAs);
      const deleteAddressPromise = firebase
        .firestore()
        .runTransaction((transaction) => {
          return transaction.get(userRef).then((userDoc) => {
            if (!userDoc.exists) {
              throw "Document does not exist!";
            }
            const oldAddresses = userDoc.data().addresses
              ? userDoc.data().addresses
              : [];
            const newAddresses = oldAddresses.filter((addr) => {
              return !(
                addr["address"] === toDelete["address"] &&
                addr["currency"] === toDelete["currency"]
              );
            });

            transaction.update(userRef, {
              uid,
              addresses: newAddresses,
            });
          });
        });

      this.setState({ submitted: true });
      deleteAddressPromise.finally(async () => {
        this.setState({ submitted: false });
        await this.props.getAddresses();
      });
    } catch (err) {
      console.log("failed!", err);
    }
  }

  async moveAddress(direction) {
    if (this.state.submitted) {
      return;
    }

    const uid = this.props.loggedInUser?.uid;
    const loggedInAs = this.props.loggedInUser?.displayName;
    const toSwap = this.props.address.item;

    try {
      const userRef = firebase.firestore().collection("user").doc(loggedInAs);
      const moveAddressPromise = firebase
        .firestore()
        .runTransaction((transaction) => {
          return transaction.get(userRef).then((userDoc) => {
            if (!userDoc.exists) {
              throw "Document does not exist!";
            }
            const oldAddresses = userDoc.data().addresses
              ? userDoc.data().addresses
              : [];

            const index = oldAddresses.findIndex((addr) => {
              return (
                addr["address"] === toSwap["address"] &&
                addr["currency"] === toSwap["currency"]
              );
            });

            if (index < 0) {
              throw "Address does not exist";
            }
            if (oldAddresses.length <= 1) {
              throw "Nowhere to move";
            }

            let newAddresses;
            if (direction === "up") {
              if (index === 0) {
                throw "Address is already at the top";
              }
              const swapSection = oldAddresses.slice(index - 1, index + 1);
              swapSection.reverse();
              newAddresses = oldAddresses
                .slice(0, index - 1)
                .concat(swapSection)
                .concat(oldAddresses.slice(index + 1));
            }
            if (direction === "down") {
              if (index + 1 === oldAddresses.length) {
                throw "Address is already at the bottom";
              }
              const swapSection = oldAddresses.slice(index, index + 2);
              swapSection.reverse();
              newAddresses = oldAddresses
                .slice(0, index)
                .concat(swapSection)
                .concat(oldAddresses.slice(index + 2));
            }

            transaction.update(userRef, {
              uid,
              addresses: newAddresses,
            });
          });
        });

      this.setState({ submitted: true });
      moveAddressPromise.finally(async () => {
        await this.props.getAddresses();
        this.setState({ submitted: false });
      });
    } catch (err) {
      console.log("failed!", err);
    }
  }

  render() {
    let leftControls, rightControls;
    if (this.state.submitted) {
      leftControls = <GridLoader color={COLOR.green} loading={true} />;
      rightControls = <GridLoader color={COLOR.green} loading={true} />;
    } else {
      leftControls = (
        <View>
          <Icon
            onPress={() => {
              this.moveAddress("up");
            }}
            type="font-awesome"
            color="grey"
            name="chevron-up"
          />
          <Icon
            onPress={() => {
              this.moveAddress("down");
            }}
            type="font-awesome"
            color="grey"
            name="chevron-down"
          />
        </View>
      );
      rightControls = <Text onPress={this.deleteAddress}>🗑</Text>;
    }
    return (
      <View style={styles.container}>
        {this.props.isOwner && leftControls}
        <View style={styles.address}>
          <AddressModal
            currency={this.props.address.item.currency}
            address={this.props.address.item.address}
          />
        </View>
        {this.props.isOwner && rightControls}
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  address: {
    flex: 1,
  },
});
export default Address;

import React, {Component} from 'react';
import { StyleSheet, View, Text } from 'react-native';

import { COLOR } from '../cssConstants';

export default class Landing extends Component {
  render() {
    return (
      <View style={styles.container}>
          <Text
            onPress={()=>{window.location.reload()}}
            style={styles.text}
          >
              404
          </Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    height:'60%',
  },
  text: {
    color: COLOR.green,
    fontSize:100,
    fontWeight: '435',
  }
})
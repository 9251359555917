const green = "#4CAF50"; //'#006600'
const lightGrey = "#DCDCDC";
const red = "#F00000";
const backgroundGreen = "#eafbea";

export const COLOR = {
  white: "#ffffff",
  green,
  lightGrey,
  header: "white",
  error: red,
  backgroundGreen,
  gold: "#FFDF00",
};

export const generalText = "Mulish";

export const CSStext = {
  color: green,
  fontFamily: "Lobster",
};

import React, { Component } from "react";
import { CSStext } from "../cssConstants";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import { Feather } from "@expo/vector-icons";
import { StyleSheet, View, Image as RNImage, Text } from "react-native";

import { COLOR } from "../cssConstants";
import { PHOTO_SIZE } from "../constants";
import firebase from "../firebase";

function addToBlobPolyfill() {
  Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
    value: function (callback, type, quality) {
      var binStr = atob(this.toDataURL(type, quality).split(",")[1]),
        len = binStr.length,
        arr = new Uint8Array(len);

      for (var i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }

      callback(new Blob([arr], { type: type || "image/png" }));
    },
  });
}

function resizeAndCropImage(file: File): Promise<Blob> {
  if (!HTMLCanvasElement.prototype.toBlob) {
    addToBlobPolyfill();
  }
  return new Promise((resolve, reject) => {
    // Create file reader
    const reader = new FileReader();
    reader.onload = (readerEvent) => {
      // Create image object
      const image = new Image();
      image.onload = (imageEvent) => {
        // Create canvas or use provided canvas
        const canvas = document.createElement("canvas");
        const maxWidth = 2 * PHOTO_SIZE || image.width;
        const maxHeight = 2 * PHOTO_SIZE || image.height;
        canvas.width = maxWidth;
        canvas.height = maxHeight;
        // Calculate scaling
        const horizontalScale = maxWidth / image.width;
        const verticalScale = maxHeight / image.height;
        const scale = Math.max(horizontalScale, verticalScale);
        // Calculate cropping
        const [width, height] = [scale * image.width, scale * image.height];
        const verticalOffset = Math.min((maxHeight - height) / 2, 0);
        const horizontalOffset = Math.min((maxWidth - width) / 2, 0);
        // Obtain the context for a 2d drawing
        const context = canvas.getContext("2d");
        if (!context) {
          return reject("Could not get the context of the canvas element");
        }
        // Draw the resized and cropped image
        context.drawImage(
          image,
          horizontalOffset,
          verticalOffset,
          width,
          height
        );
        canvas.toBlob((blob) => {
          resolve(blob);
        }, file.type);
      };
      image.src = readerEvent.target.result as string;
    };
    reader.readAsDataURL(file);
  });
}

export default class ProfilePic extends Component<
  { pageOwner: string; isOwner: boolean; userData: { uid: string } },
  { ownerData: object; profilePhotoURI: string; ownerUID: string }
> {
  state = {
    ownerData: null,
    profilePhotoURI: null,
    ownerUID: null,
  };

  _handleFileSelection = async (event: any) => {
    const {
      target: { files },
    } = event;
    for (const file of files) {
      try {
        if (!this.props.isOwner) {
          throw Error("not owner");
        }
        const resizedFile = await resizeAndCropImage(file);
        if (resizedFile.size > 100 * 1024) {
          ToastsStore.error(
            "Picture too large and mighty for our poor servers. 😅"
          );
          return;
        }
        const storageRef = firebase.storage().ref("profilePic");
        await storageRef.child(this.props.userData.uid).put(resizedFile);
        window.location.reload();
      } catch (e) {
        ToastsStore.error("Profile pic update failed. 🤕");
      }
    }
  };

  _deleteProfilePic = async () => {
    try {
      const storageRef = firebase
        .storage()
        .ref(`profilePic/${this.state.ownerUID}`);
      await storageRef.delete();
      window.location.reload();
    } catch (e) {
      console.log("deletion error", { e });
    }
  };

  _setDefaultProfilPic = async () => {
    console.log("setting default");
    this.setState({
      profilePhotoURI:
        "https://landing.cointr.ee/static/media/tree.b115ec03.png",
    });
  };
  componentDidMount() {
    const ownerUID = this.props.userData.uid;
    const profilePhotoURI = `https://firebasestorage.googleapis.com/v0/b/test-428fb.appspot.com/o/profilePic%2F${ownerUID}?alt=media&token=f54d77a1-3ae8-43e5-9564-185ae077a51d`;
    this.setState({ profilePhotoURI });
  }
  render() {
    let ownerUID = this.props.userData?.uid;
    const { isOwner } = this.props;
    return (
      <View style={styles.container}>
        {isOwner && (
          <label style={{ height: 0 }}>
            <View style={{ ...styles.editButton, display: "none" }}>
              <input type="file" onChange={this._handleFileSelection} />
            </View>
            <Feather
              name="edit"
              size={22}
              color="black"
              style={styles.editButton}
            />
          </label>
        )}
        <View style={styles.profileView}>
          {ownerUID && (
            <RNImage
              style={styles.profilePic}
              source={this.state.profilePhotoURI}
              onError={this._setDefaultProfilPic}
            />
          )}
          <Text style={styles.pageOwnerText}>{this.props.pageOwner || ""}</Text>
        </View>
        <ToastsContainer
          position={ToastsContainerPosition.BOTTOM_RIGHT}
          store={ToastsStore}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    marginHorizontal: "5px",
  },
  editButton: {
    alignSelf: "flex-end",
    paddingLeft: "90px",
    zIndex: 1,
    opacity: 0.5,
  },
  profileView: {
    marginTop: "15px",
    margin: "auto",
  },
  profilePic: {
    height: 100,
    width: 100,
    borderRadius: 50,
  },
  text: {
    color: COLOR.green,
    fontSize: 100,
    fontWeight: "400",
  },
  pageOwnerText: {
    ...CSStext,
    marginTop: "5px",
    alignSelf: "center",
    fontSize: 20,
  },
});

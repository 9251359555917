import React, { Component } from "react";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import { TextInput } from "react-native";

import { COLOR, generalText } from "../cssConstants";
import { FontAwesome } from "@expo/vector-icons";
import ReadMore from "react-native-read-more-text";
import { View, Text, TouchableOpacity } from "react-native";
import { PHOTO_SIZE } from "../constants";
import firebase from "../firebase";

const BIO_SIZE = PHOTO_SIZE + 27;
const BIO_LINES = 7;

export default class UserBio extends Component<
  {
    isOwner: boolean;
    pageOwner: string;
    userData: { bio: string };
    smallWindow: boolean;
  },
  { text: string; bioLines: number; initialTextSet: boolean }
> {
  state = { text: "", bioLines: BIO_LINES, initialTextSet: false };
  _onChangeText = async (text) => {
    this.setState({ text, initialTextSet: true });
  };
  _upDateBio = async () => {
    const { text } = this.state;
    const { isOwner, pageOwner } = this.props;
    if (isOwner && text) {
      try {
        firebase
          .firestore()
          .collection("user")
          .doc(pageOwner)
          .update({ bio: text });
        ToastsStore.success("Updated bio! 😎");
      } catch (e) {
        ToastsStore.error("Failed to upload bio. 🤕");
      }
    }
  };
  _toggleBioSize = () => {
    const currentBioLines = this.state.bioLines;
    if (currentBioLines !== BIO_LINES) {
      this.setState({ bioLines: BIO_LINES });
    } else {
      this.setState({
        bioLines: Math.max(this.state.text.split("\n").length, BIO_LINES),
      });
    }
  };
  _renderTruncatedFooter = (handlePress) => {
    return (
      <Text
        style={{ color: "green", marginTop: 5, fontFamily: generalText }}
        onPress={handlePress}
      >
        Read more
      </Text>
    );
  };
  _renderRevealedFooter = (handlePress) => {
    return (
      <Text
        style={{ color: "green", marginTop: 5, fontFamily: generalText }}
        onPress={handlePress}
      >
        Show less
      </Text>
    );
  };

  componentDidMount() {
    const { userData } = this.props;
    if (!this.state.initialTextSet) {
      this._onChangeText(userData.bio ?? "Welcome to my cointr.ee!");
    }
  }
  render() {
    const { isOwner } = this.props;
    const textContainerStyles = styles.textContainer;
    if (this.props.smallWindow) {
      textContainerStyles["alignSelf"] = "center";
      textContainerStyles["width"] = "80%";
      textContainerStyles["marginTop"] = "15px";
    }
    return (
      <View style={styles.textContainer}>
        {isOwner ? (
          <View>
            <TouchableOpacity
              style={{
                alignSelf: "flex-end",
                marginTop: "-25px",
                marginRight: "-30px",
                opacity: 0.5,
              }}
              onPress={this._upDateBio}
            >
              <FontAwesome name="save" size={24} color="black" />
            </TouchableOpacity>

            <TextInput
              style={{ fontFamily: generalText }}
              onChangeText={(text) => this._onChangeText(text)}
              value={this.state.text}
              multiline
              numberOfLines={BIO_LINES}
            />
          </View>
        ) : (
          <ReadMore
            textStyle={{ minHeight: `${BIO_SIZE}px`, fontFamily: generalText }}
            numberOfLines={this.state.bioLines}
            renderTruncatedFooter={this._renderTruncatedFooter}
            renderRevealedFooter={this._renderRevealedFooter}
          >
            {this.state.text}
          </ReadMore>
        )}
        <ToastsContainer
          position={ToastsContainerPosition.BOTTOM_RIGHT}
          store={ToastsStore}
        />
      </View>
    );
  }
}

const styles = {
  textContainer: {
    borderRadius: "5px",
    boxShadow: `1px 1px 1px grey`,
    backgroundColor: "white",
    flexGrow: 1,
    flexShrink: 1,
    marginHorizontal: "25px",
    padding: "5px",
  },
};

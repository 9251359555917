import React, { Component } from "react";
import { StyleSheet, Text, TouchableOpacity, Image, View } from "react-native";
import { openBrowserAsync } from "expo-web-browser";
import { Icon } from "react-native-elements";
import GridLoader from "react-spinners/GridLoader";

import { SOCIAL_MEDIA_SITES } from "../constants";
import { COLOR, generalText } from "../cssConstants";
import firebase from "../firebase";

class SocialMedia extends Component<
  {
    isOwner: boolean;
    loggedInUser: firebase.User;
    social: { item: { link: string; social: string } };
    getSocialMediaSites: () => {};
  },
  { submitted: boolean }
> {
  state = {
    submitted: false,
  };
  constructor(props) {
    super(props);
    this.deleteSocial = this.deleteSocial.bind(this);
    this.moveSocial = this.moveSocial.bind(this);
  }

  async deleteSocial() {
    if (this.state.submitted) {
      return;
    }

    const uid = this.props.loggedInUser?.uid;
    const loggedInAs = this.props.loggedInUser?.displayName;

    const toDelete = this.props.social.item;

    try {
      const userRef = firebase.firestore().collection("user").doc(loggedInAs);
      const deleteSocialPromise = firebase
        .firestore()
        .runTransaction((transaction) => {
          return transaction.get(userRef).then((userDoc) => {
            if (!userDoc.exists) {
              throw "Document does not exist!";
            }
            const oldSocial = userDoc.data().socialMediaSites ?? [];
            const newSocial = oldSocial.filter((addr) => {
              return !(
                addr["link"] === toDelete["link"] &&
                addr["social"] === toDelete["social"]
              );
            });

            transaction.update(userRef, {
              uid,
              socialMediaSites: newSocial,
            });
          });
        });
      this.setState({ submitted: true });

      deleteSocialPromise.finally(async () => {
        await this.props.getSocialMediaSites();
        this.setState({ submitted: false });
      });
    } catch (err) {
      console.log("failed!", err);
    }
  }

  async moveSocial(direction) {
    if (this.state.submitted) {
      return;
    }

    const uid = this.props.loggedInUser?.uid;
    const loggedInAs = this.props.loggedInUser?.displayName;
    const toSwap = this.props.social.item;

    try {
      const userRef = firebase.firestore().collection("user").doc(loggedInAs);
      const moveSocialPromise = firebase
        .firestore()
        .runTransaction((transaction) => {
          return transaction.get(userRef).then((userDoc) => {
            if (!userDoc.exists) {
              throw "Document does not exist!";
            }
            const oldSocial = userDoc.data().socialMediaSites ?? [];

            const index = oldSocial.findIndex((addr) => {
              return (
                addr["link"] === toSwap["link"] &&
                addr["social"] === toSwap["social"]
              );
            });

            if (index < 0) {
              throw "Link does not exist";
            }
            if (oldSocial.length <= 1) {
              throw "Nowhere to move";
            }

            let newSocial;
            if (direction === "up") {
              if (index === 0) {
                throw "Link is already at the top";
              }
              const swapSection = oldSocial.slice(index - 1, index + 1);
              swapSection.reverse();
              newSocial = oldSocial
                .slice(0, index - 1)
                .concat(swapSection)
                .concat(oldSocial.slice(index + 1));
            }
            if (direction === "down") {
              if (index + 1 === oldSocial.length) {
                throw "Link is already at the bottom";
              }
              const swapSection = oldSocial.slice(index, index + 2);
              swapSection.reverse();
              newSocial = oldSocial
                .slice(0, index)
                .concat(swapSection)
                .concat(oldSocial.slice(index + 2));
            }

            transaction.update(userRef, {
              uid,
              socialMediaSites: newSocial,
            });
          });
        });
      this.setState({ submitted: true });

      moveSocialPromise.finally(async () => {
        await this.props.getSocialMediaSites();
        this.setState({ submitted: false });
      });
    } catch (err) {
      console.log("failed!", err);
    }
  }

  render() {
    let leftControls, rightControls;
    if (this.state.submitted) {
      leftControls = <GridLoader color={COLOR.green} loading={true} />;
      rightControls = <GridLoader color={COLOR.green} loading={true} />;
    } else {
      leftControls = (
        <View>
          <Icon
            onPress={() => {
              this.moveSocial("up");
            }}
            type="font-awesome"
            color="grey"
            name="chevron-up"
          />
          <Icon
            onPress={() => {
              this.moveSocial("down");
            }}
            type="font-awesome"
            color="grey"
            name="chevron-down"
          />
        </View>
      );
      rightControls = <Text onPress={this.deleteSocial}>🗑</Text>;
    }
    let imagePng;
    try {
      imagePng = require(`../../assets/social/${this.props.social.item.social}.png`);
    } catch (e) {
      const baseURL = this.props.social.item.link.split("/")[2];
      imagePng = `https://${baseURL}/favicon.ico`;
    }
    const label =
      SOCIAL_MEDIA_SITES[this.props.social.item.social] ||
      this.props.social.item.social;
    return (
      <View style={styles.container}>
        {this.props.isOwner && leftControls}
        <View style={styles.container}>
          <TouchableOpacity
            style={styles.coinContainer}
            onPress={() => {
              openBrowserAsync(this.props.social.item.link);
            }}
          >
            <Image style={styles.coinLogo} source={imagePng} />
            <Text style={styles.coinText}>{label}</Text>
            <Text> </Text>
          </TouchableOpacity>
        </View>
        {this.props.isOwner && rightControls}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonStyle: {
    color: COLOR.green,
  },
  coinContainer: {
    margin: 5,
    padding: 15,
    paddingHorizontal: "10%",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: COLOR.white,
    flex: 1,
  },
  coinLogo: {
    width: 30,
    height: 30,
  },
  coinText: {
    flexShrink: 1,
    margin: "auto",
    padding: 5,
    color: COLOR.green,
    fontFamily: generalText,
  },
});

export default SocialMedia;

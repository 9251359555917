import React, { Component } from "react";
import {
  View,
  StyleSheet,
  FlatList,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
} from "react-native";
import { COLOR, generalText } from "../cssConstants";

export type BlurbProps = {
  bio: string;
  hasBio: boolean;
  hasPic: boolean;
  objectID: string;
  uid: string;
};

class UserBlurb extends Component<
  BlurbProps,
  { useDefaultPhoto: boolean; ownerUID: string | undefined }
> {
  state = {
    useDefaultPhoto: false,
    ownerUID: undefined,
  };
  _setDefaultProfilPic = async () => {
    this.setState({
      useDefaultPhoto: true,
    });
    //"https://landing.cointr.ee/static/media/tree.b115ec03.png",
  };
  componentDidMount() {
    const ownerUID = this.props.uid;
    this.setState({ ownerUID });
  }
  componentDidUpdate() {
    if (this.state.ownerUID !== this.props.uid) {
      const ownerUID = this.props.uid;
      this.setState({ ownerUID });
    }
  }

  render() {
    let profilePhotoURI;
    if (this.state.useDefaultPhoto) {
      profilePhotoURI =
        "https://landing.cointr.ee/static/media/tree.b115ec03.png";
    } else {
      const ownerUID = this.props.uid;
      profilePhotoURI = `https://firebasestorage.googleapis.com/v0/b/test-428fb.appspot.com/o/profilePic%2F${ownerUID}?alt=media&token=f54d77a1-3ae8-43e5-9564-185ae077a51d`;
    }

    return (
      <View style={styles.hitBox}>
        <Image
          key={profilePhotoURI}
          style={styles.profilePic}
          source={{ uri: profilePhotoURI }}
          onError={this._setDefaultProfilPic}
        />
        <TouchableOpacity
          onPress={() => {
            window.open(`/${this.props.objectID}`, "_self");
          }}
          style={styles.textBox}
        >
          <Text numberOfLines={1} style={styles.username}>
            {this.props.objectID}
          </Text>
          {this.props.bio && (
            <Text numberOfLines={2} style={styles.hitText}>
              {(this.props.bio || "").replace(/↵↵/g, " ").replace(/\s/g, " ")}
            </Text>
          )}
        </TouchableOpacity>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  hitBox: {
    alignSelf: "center",
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    marginTop: "5px",
    margin: "5px",
    backgroundColor: "white",
    padding: "10px",
    borderRadius: 5,
    boxShadow: `1px 1px 1px grey`,
    width: "95%",
  },
  profilePic: {
    height: 50,
    width: 50,
    borderRadius: 25,
    padding: "15px",
  },
  textBox: {
    paddingHorizontal: "10px",
    width: "90%",
  },
  hitText: { width: "90%", fontFamily: generalText },
  username: {
    flex: 1,
    flexGrow: 1,
    fontWeight: "bold",
    fontFamily: generalText,
  },
  searchSection: {
    marginLeft: 20,
    marginTop: "30px",
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  searchIcon: {
    padding: 10,
  },
  input: {
    flex: 1,
    height: 40,
    paddingHorizontal: 10,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: COLOR.green,
    backgroundColor: "#fff",
    fontFamily: generalText,
  },
});

export default UserBlurb;

import React, { Component } from "react";
import { AntDesign } from "@expo/vector-icons";
import queryString from "query-string";
import {
  View,
  StyleSheet,
  FlatList,
  Text,
  Image,
  TouchableOpacity,
  TextInput,
} from "react-native";

import algoliasearch from "algoliasearch/lite";
import { COLOR, generalText } from "../cssConstants";
import { MAX_SITE_WIDTH } from "../constants";
import UserBlurb, { BlurbProps } from "./UserBlurb";
import { withLocation } from "../util"; // TODO Is there an existing file for utils?

const client = algoliasearch("WUNL7UWNAD", "89fe2c6422eb3edb84761755a66558ef");
const index = client.initIndex("prod_cointree_users");

class Search extends Component<{}, { hits: BlurbProps[]; text: string }> {
  state = {
    hits: null,
    text: "",
  };
  async componentDidMount() {
    const { search } = queryString.parse(this.props.location.search);
    if (search && !Array.isArray(search)) {
      const results = await index.search(search);
      this.setState({ hits: results.hits });
    }
  }
  updateSearch = (text) => {
    this.setState({ text });
  };
  async search() {
    if (!this.state.text) {
      return;
    }
    const results = await index.search(this.state.text);
    this.setState({ hits: results.hits as BlurbProps[] });
  }
  render() {
    return (
      <View style={{ alignSelf: "center", maxWidth: MAX_SITE_WIDTH }}>
        <View style={styles.searchSection}>
          <TextInput
            style={styles.input}
            placeholder="Search for a user..."
            onChangeText={this.updateSearch}
            value={this.state.text}
            onSubmitEditing={this.search.bind(this)}
          />
          <TouchableOpacity onPress={this.search.bind(this)}>
            <AntDesign
              style={styles.searchIcon}
              name="search1"
              size={24}
              color={COLOR.green}
            />
          </TouchableOpacity>
        </View>
        <FlatList
          style={{
            alignSelf: "center",
            maxWidth: MAX_SITE_WIDTH,
            width: "100%",
          }}
          data={this.state.hits || []}
          renderItem={({ item }) => <UserBlurb {...item} />}
          ListEmptyComponent={
            <Text
              style={{
                marginTop: "50px",
                alignSelf: "center",
                fontFamily: generalText,
              }}
            >
              Nothing found.
            </Text>
          }
        />
      </View>
    );
  }
}
const styles = StyleSheet.create({
  hitBox: {
    alignSelf: "center",
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    marginTop: "25px",
    margin: "5px",
    backgroundColor: "white",
    padding: "10px",
    borderRadius: 5,
    boxShadow: `1px 1px 1px grey`,
    width: "95%",
  },
  profilePic: {
    height: 50,
    width: 50,
    borderRadius: 25,
    padding: "15px",
  },
  textBox: {
    paddingHorizontal: "10px",
    width: "90%",
  },
  hitText: { width: "90%", fontFamily: generalText },
  username: {
    flex: 1,
    flexGrow: 1,
    fontWeight: "bold",
    fontFamily: generalText,
  },
  searchSection: {
    marginLeft: 20,
    marginTop: "30px",
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  searchIcon: {
    padding: 10,
  },
  input: {
    flex: 1,
    height: 40,
    paddingHorizontal: 10,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: COLOR.green,
    backgroundColor: "#fff",
    fontFamily: generalText,
  },
});

export default withLocation(Search)

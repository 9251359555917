import React, { Component } from "react";
import { StyleSheet, Linking, View, TouchableOpacity } from "react-native";
import { Text } from "react-native-elements";
import { Octicons } from "@expo/vector-icons";

import { COLOR, CSStext, generalText } from "../cssConstants";
import MessageList from "./MessageList";
import { MAX_SITE_WIDTH } from "../constants";

export default class Landing extends Component<{}, { muted: boolean }> {
  state = {
    muted: true,
  };
  render() {
    return (
      <View
        style={{ width: "100%", maxWidth: MAX_SITE_WIDTH, alignSelf: "center" }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            paddingTop: "20px",
            paddingBottom: "25px",
            margin: "10px",
            marginBottom: "-5px",
          }}
        >
          <Text style={styles.headerText}>Recent Activity:</Text>
          <TouchableOpacity
            onPress={() => {
              this.setState({ muted: !this.state.muted });
            }}
            style={{ marginLeft: "auto" }}
          >
            {this.state.muted ? (
              <Octicons name="mute" size={24} color={COLOR.green} />
            ) : (
              <Octicons name="unmute" size={24} color={COLOR.green} />
            )}
          </TouchableOpacity>
        </View>
        <MessageList mainFeed={true} muted={this.state.muted} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  recentMessages: {
    width: "50%",
  },
  card: {
    //backgroundColor: '#000000',
    borderColor: "#ffffff",
  },
  headerText: {
    fontWeight: "bold",
    fontSize: 20,
    color: COLOR.green,
    ...CSStext,
  },
});

import firebase from 'firebase/compat/app';

import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDqeX9z20n2Tw1MnIUOBJXSlGw8RjtR0t0",
  authDomain: "test-428fb.firebaseapp.com",
  databaseURL: "https://test-428fb.firebaseio.com",
  projectId: "test-428fb",
  storageBucket: "test-428fb.appspot.com",
  messagingSenderId: "902954058440",
  appId: "1:902954058440:web:ca0bc4cd87493931bc9bfd",
  measurementId: "G-H00XZ20KTE"
};;

firebase.initializeApp(firebaseConfig)

export default firebase;

import Modal from "modal-enhanced-react-native-web";
import QRCode from "react-qr-code";
import { Entypo } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import React, { Component } from "react";
import {
  Clipboard,
  Dimensions,
  Image,
  TouchableOpacity,
  View,
  StyleSheet,
  Text,
} from "react-native";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import { Button } from "react-native-elements";
import { Icon } from "react-native-elements";

import MessageForm from "./MessageForm";
import {
  COIN_NAME,
  URI,
  SUPPORTED_MESSAGE_COINS,
  MAX_SITE_WIDTH,
} from "../constants";
import { COLOR, generalText } from "../cssConstants";
import * as Linking from "expo-linking";

export default class AddressModal extends Component<
  { currency: string; address: string },
  {
    visibleModal: boolean | null;
    visibleMessageForm: boolean;
    amount: number | null;
  }
> {
  state = {
    visibleModal: null,
    visibleMessageForm: false,
    amount: null,
  };

  _setMessageForm = () => {
    this.setState({ visibleMessageForm: !this.state.visibleMessageForm });
  };
  _renderButton = (ticker, onPress) => {
    let assetLocation = ticker.toLowerCase();
    if (!COIN_NAME[assetLocation.toUpperCase()]) {
      assetLocation = "tree";
    }
    return (
      <View>
        <TouchableOpacity
          onPress={
            SUPPORTED_MESSAGE_COINS.includes(ticker)
              ? this._setMessageForm
              : onPress
          }
          style={styles.coinContainer}
        >
          <LinearGradient
            // Background Linear Gradient
            colors={["#65cf69", "transparent"]}
            start={[0, 0]}
            end={[1, 0]}
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              height: "100%",
            }}
          />
          <Image
            style={styles.coinLogo}
            source={require(`../../assets/${assetLocation}.png`)}
          />
          <Text style={styles.coinText}>
            {COIN_NAME[ticker.toUpperCase()]
              ? COIN_NAME[ticker.toUpperCase()]
              : ticker}
          </Text>
          {SUPPORTED_MESSAGE_COINS.includes(ticker) ? (
            <TouchableOpacity onPress={this._setMessageForm}>
              <Text style={styles.headerText}>✉️</Text>
            </TouchableOpacity>
          ) : (
            <Text style={styles.headerText}>{" ".repeat(7)}</Text>
          )}
        </TouchableOpacity>
        <MessageForm
          collapsed={this.state.visibleMessageForm}
          renderModal={() => this.setState({ visibleModal: true })}
          hideModal={() => this.setState({ visibleModal: false })}
          setAmount={(amount) => this.setState({ amount })}
          address={this.props.address}
          currency={this.props.currency}
        />
      </View>
    );
  };

  _renderSendTransactionToWallet = (uri_address) => {
    if (!URI[this.props.currency]) {
      // This button would be a 404, don't render it
      //
      // NOTE: At very least, this is for users with "custom" coin
      // types that don't have a ticker in the system. It might also
      // happen for half-supported stuff that somehow doesn't make it
      // into URI[]. This whole thing is bound to be a mess.
      return <></>
    }
    return (
      <View style={styles.coinContainer}>
        <Text
          style={styles.coinText}
          onPress={() => {
            Linking.openURL(uri_address);
          }}
        >
          Send transaction to your wallet!
          <Entypo
            name="share-alternative"
            size={20}
            color="white"
            style={{ marginHorizontal: "5px" }}
          />
        </Text>
      </View>
    )
  }

  _renderModalContent = () => {
    let uri_address;

    if (URI[this.props.currency]) {
      uri_address = this.props.address.includes(URI[this.props.currency])
        ? this.props.address
        : URI[this.props.currency] + ":" + this.props.address;
    } else {
      // if we don't know the URI, just show the address
      uri_address = this.props.address;
    }

    if (this.state.amount) {
      uri_address = `${uri_address}?amount=${this.state.amount}`;
    }

    const windowDimension = Dimensions.get("window");
    const qrEdge = Math.floor(
      Math.min(windowDimension.height, windowDimension.width) / 3
    );
    let assetLocation = this.props.currency.toLowerCase();
    if (!COIN_NAME[assetLocation.toUpperCase()]) {
      assetLocation = "tree";
    }
    return (
      <View style={styles.qrContainer}>
        <View style={styles.coinContainer}>
          <Image
            style={styles.coinLogo}
            source={require(`../../assets/${assetLocation}.png`)}
          />
          <Text style={styles.coinText}>
            {COIN_NAME[this.props.currency.toUpperCase()]
              ? COIN_NAME[this.props.currency.toUpperCase()]
              : this.props.currency}
          </Text>
          <Icon
            type="font-awesome"
            color="white"
            name="copy"
            onPress={() => {
              Clipboard.setString(uri_address.split(":").slice(-1)[0]);
              ToastsStore.success(
                `Copied "${uri_address.split(":").slice(-1)[0]}" to clipboard!`
              );
            }}
          />
        </View>
	{this._renderSendTransactionToWallet(uri_address)}
        <Text
          style={{
            textAlign: "center",
            marginVertical: "10px",
            fontSize: 20,
            color: "white",
            fontFamily: generalText,
          }}
        >
          Send EXACTLY{" "}
          <Text
            style={{
              color: "orange",
              fontSize: 20,
              fontWeight: "bold",
              fontFamily: generalText,
            }}
          >
            {this.state.amount}
          </Text>{" "}
          to{" "}
          <Text
            style={{
              color: "orange",
              fontSize: 20,
              fontWeight: "bold",
              fontFamily: generalText,
            }}
          >
            {this.props.address}
          </Text>
        </Text>

        <View
          style={{
            borderWidth: 40,
            borderColor: "white",
          }}
        >
          <QRCode style={styles.qr} value={uri_address} size={qrEdge} />
        </View>
        <Text style={styles.explainText}>
          This window will autoclose once a confirmation has been found, or it
          can be manually closed once payment has been sent. If you are logged
          in, you will receive an email receipt of your payment.
        </Text>
        <Button
          onPress={() => this.setState({ visibleModal: false })}
          type="clear"
          titleStyle={styles.buttonStyle}
          title="Close"
        />
        <ToastsContainer
          position={ToastsContainerPosition.BOTTOM_RIGHT}
          store={ToastsStore}
        />
      </View>
    );
  };

  render() {
    return (
      <View>
        {this._renderButton(this.props.currency, () =>
          this.setState({ visibleModal: true })
        )}
        <Modal isVisible={this.state.visibleModal}>
          {this._renderModalContent()}
        </Modal>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  buttonStyle: {
    color: COLOR.error,
  },
  coinContainer: {
    margin: 5,
    padding: 15,
    paddingHorizontal: "10%",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: COLOR.green,
    flex: 1,
  },
  coinLogo: {
    width: 30,
    height: 30,
  },
  coinText: {
    flexShrink: 1,
    margin: "auto",
    padding: 5,
    color: "white",
    zIndex: 999,
    fontFamily: generalText,
  },
  qrContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: "50px",
  },
  qr: {
    alignSelf: "center",
    borderWidth: 1,
    borderColor: COLOR.green,
  },
  explainText: {
    marginVertical: "10px",
    textAlign: "center",
    color: "white",
    fontSize: 20,
    maxWidth: MAX_SITE_WIDTH,
    fontFamily: generalText,
  },
  headerText: {
    fontSize: 20,
    fontWeight: "500",
    textAlign: "center",
    fontFamily: generalText,
  },
});

import React, { Component } from "react";
import { StyleSheet, Text, TextInput, View } from "react-native";
import { Button, Input } from "react-native-elements";
import * as Linking from "expo-linking";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";

import { COLOR, generalText } from "../cssConstants";
import firebase from "../firebase";

class Username extends Component<
  { loggedInUser: firebase.User },
  { username: string | null }
> {
  state = {
    username: null,
  };

  _setUsername = async () => {
    const firestore = firebase.firestore();
    const uid = this.props.loggedInUser?.uid;
    const { username } = this.state;

    let cleanedUsername = username.replace(/[^0-9a-zA-Z_-]/g, "");
    if (cleanedUsername != username) {
      ToastsStore.error(
        "Username must be composed of numbers, letters and the characters - and _."
      );
      this.setState({
        username: cleanedUsername,
      });
      return;
    }
    /**
     * Wait till after validating to lowercase it to allow capitalization
     */
    cleanedUsername = cleanedUsername.toLowerCase();

    if (cleanedUsername.length < 3) {
      ToastsStore.error("Username must be longer than 3 characters.");
      return;
    }
    if (cleanedUsername.length > 20) {
      ToastsStore.error("Username must be shorter than 20 characters.");
      this.setState({
        username: cleanedUsername,
      });
      return;
    }

    //transform this into an update operation
    try {
      const batch = firestore.batch();

      let data = { uid };
      const currentUsernames = await firestore
        .collection("user")
        .where("uid", "==", uid)
        .get();
      if (currentUsernames.docs.length > 0) {
        data = currentUsernames.docs[0].data();
      }

      currentUsernames.forEach((doc) => {
        batch.delete(doc.ref);
      });

      const newUsername = firestore.collection("user").doc(cleanedUsername);
      batch.set(newUsername, data);

      const result = await batch.commit();

      await firebase.auth().currentUser.updateProfile({
        displayName: cleanedUsername,
      });
      Linking.openURL(`/${cleanedUsername}`);
    } catch (err) {
      let errorMessage =
        err.message ||
        "There was an error trying to save your username. Please refresh the page and try again.";
      if (err.code === "permission-denied") {
        errorMessage = "Username unavailable.";
      }
      ToastsStore.error(errorMessage);
    }
  };

  render() {
    const username = this.props.loggedInUser?.displayName;

    return (
      <View style={styles.container}>
        <Input
          inputStyle={{ fontFamily: generalText }}
          value={this.state.username ?? username ?? ""}
          onChange={(e) => this.setState({ username: e.target.value })}
          placeholder="username"
          leftIcon={{ type: "font-awesome", name: "user", color: COLOR.green }}
          leftIconContainerStyle={styles.icon}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              this._setUsername();
            }
          }}
        ></Input>
        <Button
          title={"update username"}
          onPress={this._setUsername}
          titleStyle={styles.button}
          type={"clear"}
          raised={true}
        />
        <ToastsContainer
          position={ToastsContainerPosition.BOTTOM_RIGHT}
          store={ToastsStore}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    justifyContent: "center",
    alignItems: "stretch",
  },
  icon: {
    left: 0,
    paddingRight: 10,
  },
  button: {
    color: COLOR.green,
    alignSelf: "stretch",
    fontFamily: generalText,
  },
});
export default Username;

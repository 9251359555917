import React, { Component } from "react";
import { LinearGradient } from "expo-linear-gradient";
import { TouchableOpacity } from "react-native-web";
import { Ionicons } from "@expo/vector-icons";
import { TwitterShareButton, TwitterIcon } from "react-share";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import ScaleLoader from "react-spinners/ScaleLoader";
import PuffLoader from "react-spinners/PuffLoader";

import firebase from "../firebase";
import { BLOCKCHAIR_DICT, BLOCKCHAIR_TXN_PREFIX } from "../constants";
import { COLOR, generalText } from "../cssConstants";
import { Image, StyleSheet, View, Text } from "react-native";
import { withMatch } from "../util"; // TODO Is there an existing file for utils?

class Signature extends Component<{ name: string | null }, {}> {
  render() {
    let name = this.props.name || "Anonymous";
    return (
      <Text style={{ alignSelf: "flex-end" }}>
        -
        <Text
          style={styles.userNameText}
          onPress={() => {
            if (this.props.name !== "Anonymous") {
              window.open(`/${this.props.name}`, "_self");
            }
          }}
        >
          {name}
        </Text>
      </Text>
    );
  }
}

class Content extends Component<{ text: string; senderName: string }> {
  render() {
    return (
      <CardContent>
        <LinearGradient
          // Background Linear Gradient
          colors={["grey", "transparent"]}
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            height: 1,
          }}
        ></LinearGradient>
        <Text style={styles.generalText}>{this.props.text}</Text>
        <View>
          <Signature name={this.props.senderName} />
        </View>
      </CardContent>
    );
  }
}

type MessageItem = {
  messageRetreived: boolean;
  id?: string;
  confirmations?: number;
  dialogOpen: boolean;
  senderName?: string;
  senderTwitter?: string;
  cryptoCurrency?: string;
  txid?: string;
  uidSender?: string;
  uidRecipient?: string;
  recipientName?: string;
};

class Message extends Component<
  {
    messageData: { item: MessageItem };
    match: { params: { messageId } };
  },
  MessageItem & {
    loggedInUid?: string;
    loggedInDisplayName?: string;
    recipientName?: string;
  }
> {
  state = {
    dialogOpen: false,
    messageRetreived: false,
  };

  openDialog = async () => {
    this.setState({ dialogOpen: true });
  };
  handleClose = () => {
    this.setState({ dialogOpen: false });
  };
  async confirmDelete() {
    const { id } = this.state;
    await firebase.firestore().collection("messages").doc(id).delete();
    window.open(`/${this.state.recipientName}`, "_self");
  }

  async loadMessage() {
    let newState;
    if (this.props.messageData) {
      newState = this.props.messageData.item;
    } else {
      const messageDoc = await firebase
        .firestore()
        .collection("messages")
        .doc(this.props.match.params.messageId)
        .get();
      if (!messageDoc.data()) {
        window.open("/a/FourZeroFour", "_self");
      }
      newState = messageDoc.data();
      newState["id"] = messageDoc.id;
    }
    this.setState({
      ...newState,
      messageRetreived: true,
    });
    // Get names in delayed fashion. We don't know if it'll fire off before
    // the state is updated, so we'll just send the IDs it needs to make the update.
    this.getSenderRecieverNames(newState.uidSender, newState.uidRecipient);
  }

  displayOwnerOnlyFields(loggedInUser) {
    if (!loggedInUser) {
      return;
    }
    const { uid, displayName, isAnonymous } = loggedInUser;
    if (isAnonymous) {
      return;
    }
    this.setState({
      loggedInUid: uid,
      loggedInDisplayName: displayName,
    });
  }

  openTxid = () => {
    const txPrefix = BLOCKCHAIR_TXN_PREFIX[this.state.cryptoCurrency] || '';
    window.open(
      `https://blockchair.com/${
        BLOCKCHAIR_DICT[this.state.cryptoCurrency]
      }/transaction/${txPrefix}${this.state.txid}`
    );
  };

  onTextPress = () => {
    if (this.props.messageData) {
      window.open(`/a/message/${this.props.messageData.item.id}`, "_self");
    }
  };

  async getSenderRecieverNames(uidSender, uidRecipient) {
    const idList = [uidSender, uidRecipient].filter((x) => x);
    if (idList.length > 0) {
      const users = await firebase
        .firestore()
        .collection("user")
        .where("uid", "in", idList)
        .get();
      const senderList = users.docs.filter(
        (u) => u.data().uid === uidSender
      );
      const senderName = senderList.length > 0 ? senderList[0].id : "Anonymous";
      let senderTwitter;
      try {
        if (senderName !== "Anonymous") {
          const senderSocialMediaSites = senderList[0].get("socialMediaSites");
          const senderTwitterLink = senderSocialMediaSites.filter((site) => {
            return site.social === "twitter";
          })[0]?.link;
          if (senderTwitterLink.slice(-1) === "/") {
            senderTwitter = senderTwitterLink.split("/").slice(-2)[0];
          } else {
            senderTwitter = senderTwitterLink.split("/").slice(-1)[0];
          }
        }
      } catch (e) {}
      const recipientName = users.docs.filter(
        (u) => u.data().uid === uidRecipient
      )[0]?.id;
      this.setState({
        senderName,
        recipientName,
        senderTwitter,
      });
    }
  }

  async componentDidMount() {
    if (!this.state.messageRetreived) {
      this.loadMessage();
      firebase
        .auth()
        .onAuthStateChanged(this.displayOwnerOnlyFields.bind(this));
    }
  }

  render() {
    const messageOwner =
      this.state.loggedInUid &&
      this.state.loggedInUid === this.state.uidRecipient;

    const { senderName, senderTwitter } = this.state;
    const twitterAt = senderTwitter
      ? `@${senderTwitter.replace(/@/, "")}`
      : senderName || "Anonymous";
    let confirmations =
      this.props.messageData?.item.confirmations ?? this.state.confirmations;

    return (
      <View style={{ width: "100%" }}>
        <Card style={{ margin: 10 }}>
          {this.state.messageRetreived ? (
            <View style={styles.globalAmountLine}>
              <LinearGradient
                // Background Linear Gradient
                colors={["#ffee70", "transparent"]}
                start={[0, 0]}
                end={[1, 0]}
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  height: "100%",
                }}
              />
              <View style={styles.amountLine}>
                <View style={styles.amountLine}>
                  <TouchableOpacity
                    onPress={this.openTxid}
                    style={styles.amountLine}
                  >
                    <Text style={styles.generalText}>
                      ${this.state.fiatAmount} in{" "}
                    </Text>
                    <Image
                      style={styles.image}
                      source={require(`../../assets/${this.state.cryptoCurrency.toLowerCase()}.png`)}
                    />
                  </TouchableOpacity>
                  <Text style={styles.generalText}> to </Text>
                  {this.state.recipientName && (
                    <Text
                      style={styles.userNameText}
                      onPress={() => {
                        window.open(`/${this.state.recipientName}`, "_self");
                      }}
                    >
                      {this.state.recipientName}
                    </Text>
                  )}
                </View>
                {confirmations === 0 && (
                  <View
                    style={{
                      margin: "auto",
                      opacity: 0.5,
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{
                        marginHorizontal: "10px",
                        fontFamily: generalText,
                      }}
                    >
                      pending confirmations...
                    </Text>
                    <PuffLoader size={12} color={COLOR.green} />
                  </View>
                )}
                {messageOwner && (
                  <TouchableOpacity
                    onPress={this.openDialog}
                    style={{ marginLeft: "auto", paddingRight: "1%" }}
                  >
                    <Ionicons name="ios-close" size={20} color="red" />
                  </TouchableOpacity>
                )}
              </View>
            </View>
          ) : (
            <View style={styles.amountLine}>
              <ScaleLoader height={12} color={COLOR.lightGrey} />
            </View>
          )}
          {this.state.messageRetreived ? (
            <View>
              {this.props.messageData ? (
                <TouchableOpacity onPress={this.onTextPress}>
                  <Content
                    senderName={this.state.senderName}
                    text={this.state.text}
                  />
                </TouchableOpacity>
              ) : (
                <Content
                  senderName={this.state.senderName}
                  text={this.state.text}
                />
              )}
            </View>
          ) : (
            <View style={{ alignItems: "center" }}>
              <ScaleLoader height={36} color={COLOR.lightGrey} />
            </View>
          )}
          {messageOwner && (
            <View style={styles.shareButtons}>
              <Text style={{ marginRight: "1%", opacity: 0.4 }}>
                Thank them publicly!
              </Text>
              <TwitterShareButton
                title={`Thank you, ${twitterAt}!`}
                url={`https://cointr.ee/a/message/${this.state.id}`}
                hashtags={["bitcoin", this.state.cryptoCurrency]}
                via={"CryptoCoinTree"}
              >
                <TwitterIcon size={20} round />
              </TwitterShareButton>
            </View>
          )}
        </Card>
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete this message?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this message? The operation is
              permanent.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              Cancel
            </Button>
            <Button onClick={this.confirmDelete.bind(this)} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  image: {
    width: 15,
    height: 15,
    marginTop: 2,
  },
  senderLine: {
    opacity: 0.4,
  },
  amountLine: {
    //opacity:.6,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  globalAmountLine: {
    backgroundColor: COLOR.gold,
    padding: "2%",
  },
  shareButtons: {
    flexDirection: "row",
    //backgroundColor: COLOR.lightGrey,
    justifyContent: "flex-start",
    padding: "2%",
  },
  generalText: {
    fontFamily: generalText,
  },
  userNameText: {
    fontWeight: "bold",
    fontFamily: generalText,
  },
});

export default withMatch(Message);

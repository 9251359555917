import React, { Component } from "react";
import { StyleSheet, Text, TextInput, View } from "react-native";
import { Button } from "react-native-elements";
import * as Linking from "expo-linking";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";

import { COLOR, generalText } from "../cssConstants";
import firebase from "../firebase";

class MinimumSend extends Component<
  { loggedInUser: firebase.User; amount: Number },
  { username: string | null }
> {
  state = {
    username: null,
    amount: undefined,
  };

  updateMinimumSendAmount = async (amount: number) => {
    console.log("passed");
    const minimum = parseInt(amount) || 0;
    const firestore = firebase.firestore();
    const displayName = this.props.loggedInUser?.displayName;
    try {
      await firestore.collection("user").doc(displayName).update({
        "preferences.sendAmount.minimum": minimum,
      });
      ToastsStore.success("Updated!");
      this.setState({ amount: minimum });
    } catch (err) {
      let errorMessage =
        err.message ||
        "There was an error trying to update the minimum donation amount. Please refresh the page and try again.";
      ToastsStore.error(errorMessage);
    }
  };

  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.button}>Set minimum donation amount ($): </Text>
        <TextInput
          style={{ textAlign: "center", width: "25%" }}
          value={String(this.state.amount ?? this.props.amount ?? 0)}
          onChange={(e) =>
            this.updateMinimumSendAmount(parseInt(e.target.value))
          }
        />

        <ToastsContainer
          position={ToastsContainerPosition.BOTTOM_RIGHT}
          store={ToastsStore}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    margin: "auto",
  },
  icon: {
    left: 0,
    paddingRight: 10,
  },
  label: {
    marginVertical: "auto",
    fontSize: 18,
    alignSelf: "stretch",
    fontFamily: generalText,
  },
  button: {
    marginVertical: "auto",
    fontSize: 18,
    color: COLOR.green,
    alignSelf: "stretch",
    fontFamily: generalText,
  },
});

export default MinimumSend;

import _ from "underscore";
import React, { Component } from "react";

import MessageList from "./MessageList";
import firebase from "../firebase";
import { MAX_SITE_WIDTH } from "../constants";
import { generalText, CSStext } from "../cssConstants";
import { View, Text, FlatList } from "react-native";

import Message from "./Message";
import UserBlurb from "./UserBlurb";
import { withMatch } from "../util"; // TODO Is there an existing file for utils?

class DisplayMessage extends Component<
  { match: { params: { messageId: string } } },
  { featuredUsers: { objectID: string }[] }
> {
  state = {
    featuredUsers: [],
  };
  async getFeaturedUsers() {
    const featuredUserIdList = _.sample(
      [
        "TiivbZyX8sRuI70SNrzW3ZWbN4M2", //naomi
        "S9BfOJZNnpMEp7njaAYy8hOd3yB2", //joel
        "fCeY2o23WrPjUtABgRxND2I4qe13", //ddp
        "bfpjYGlkOicY12e1YlfLNAMLdES2", //genesohoforum
        "sDukTQ3NPHex9XQ5gZyCdIyCfME2", //scottcbusiness
        "DRMg06RY7WZZWlOXBKgeNfFA9Gz2", //kennbosack
        "5z1qurKqgZQlJrnch8XWSxzc40C3", //bitcoinjake09
        "VN4OIBmZHhdWI0vy0mzno3ZdSie2", //davidbond
        "G2nCRw8K29UJwOj66jgl22TvdIV2", //blockblanc
      ],
      3
    );
    const users = await firebase
      .firestore()
      .collection("user")
      .where("uid", "in", featuredUserIdList)
      .get();
    this.setState({
      featuredUsers: users.docs.map((u) => {
        return { objectID: u.id, ...u.data() };
      }),
    });
  }
  componentDidMount() {
    this.getFeaturedUsers();
  }

  render() {
    const {
      match: {
        params: { messageId },
      },
    } = this.props;
    const ignoreMessages = [messageId];
    return (
      <View>
        {/* <ImageBackground
          source={{ uri: require("../../assets/message-background.jpg") }}
          style={{
          }}
          imageStyle={{ opacity: 0.3 ,
          
            position: "absolute",
            top: 0,
            left: 0,
          }}
        /> */}
        <View
          style={{
            maxWidth: MAX_SITE_WIDTH,
            alignSelf: "center",
            alignItems: "flex-start",
            marginTop: "5%",
          }}
        >
          <Message {...this.props} />
          <Text
            style={{
              fontSize: 20,
              ...CSStext,
              width: "95%",
              alignSelf: "center",
              marginTop: 30,
              marginBottom: 20,
            }}
          >
            Featured Users:
          </Text>
          <FlatList
            style={{
              maxWidth: MAX_SITE_WIDTH,
              width: "100%",
              flexGrow: 0,
            }}
            data={this.state.featuredUsers}
            renderItem={({ item }) => <UserBlurb {...item} />}
            ListEmptyComponent={
              <Text
                style={{
                  alignSelf: "center",
                  fontFamily: generalText,
                }}
              >
                Nothing found.
              </Text>
            }
          />
          <Text
            style={{
              fontSize: 20,
              ...CSStext,
              width: "95%",
              alignSelf: "center",
              marginTop: 30,
              marginBottom: 15,
            }}
          >
            Other recent messages:
          </Text>
          <MessageList mainFeed={true} ignoreMessages={ignoreMessages} />
        </View>
      </View>
    );
  }
}

export default withMatch(DisplayMessage);

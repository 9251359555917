import React, { Component } from "react";
import { AntDesign } from "@expo/vector-icons";
import { HamburgerCollapse } from "react-animated-burgers";
import {
  StyleSheet,
  TouchableOpacity,
  Image,
  View,
  Linking,
} from "react-native";
import { Header, Text } from "react-native-elements";

import Dropdown from "./Dropdown";
import firebase from "../firebase";
import { COLOR, CSStext, generalText } from "../cssConstants";
import { withLocation } from "../util"; // TODO Is there an existing file for utils?

const BUTTON_WIDTH = 20;
class CustomHeader extends Component<
  {
    loggedInUser: firebase.User | null;
    location: { search: string; pathname: string };
  },
  { dropdownHidden: boolean }
> {
  _toggle = () => {
    const { dropdownHidden } = this.state;
    this.setState({ dropdownHidden: !dropdownHidden });
  };

  state = {
    dropdownHidden: true,
  };

  render() {
    let { dropdownHidden } = this.state;
    return (
      <View>
        <Header
          containerStyle={styles.header}
          leftComponent={
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://landing.cointr.ee");
              }}
            >
              <Image
                style={{ height: 32, width: 32 }}
                source={require("../../assets/tree.png")}
                resizeMode="contain"
              />
            </TouchableOpacity>
          }
          centerComponent={
            <Text
              onPress={() => {
                Linking.openURL("/");
              }}
              style={[styles.text, { fontWeight: "bold" }]}
            >
              CoinTr.ee
            </Text>
          }
          rightComponent={
            this.props.loggedInUser?.uid ||
            this.props.location.pathname === "/a/verify-email" ||
            this.props.location.pathname === "/a/username" ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    window.open("/a/search", "_self");
                  }}
                >
                  <AntDesign
                    style={{ marginTop: 3, marginRight: "15px" }}
                    name="search1"
                    size={24}
                    color={COLOR.green}
                  />
                </TouchableOpacity>
                <HamburgerCollapse
                  buttonWidth={BUTTON_WIDTH}
                  isActive={!this.state.dropdownHidden}
                  toggleButton={this._toggle}
                  buttonColor="white"
                  barColor={COLOR.green}
                />
              </View>
            ) : (
              <View style={{ alignItems: "center", flexDirection: "row" }}>
                <Text
                  style={{
                    color: COLOR.green,
                    fontFamily: generalText,
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  <Text
                    onPress={() => {
                      Linking.openURL("/a/login");
                    }}
                  >
                    Login
                  </Text>
                  <Text
                    style={{ color: "darkorange", marginHorizontal: "2px" }}
                  >
                    |
                  </Text>
                  <Text
                    onPress={() => {
                      Linking.openURL("/a/signup");
                    }}
                  >
                    Sign Up
                  </Text>
                </Text>
              </View>
            )
          }
        />

        {this.props.loggedInUser && (
          <Dropdown
            expanded={dropdownHidden}
            loggedInUser={this.props.loggedInUser}
          />
        )}
      </View>
    );
  }
}

let styles = StyleSheet.create({
  header: {
    backgroundColor: COLOR.header,
    justifyContent: "space-between",
  },
  text: { fontSize: 24, ...CSStext },
});

export default withLocation(CustomHeader)

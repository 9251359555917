import React, { Component } from "react";
import { View, StyleSheet, Linking, Text } from "react-native";
import { CSStext, generalText } from "../cssConstants";
import { MAX_SITE_WIDTH } from "../constants";

class FAQ extends Component {
  render() {
    return (
      <View style={styles.container}>
        <Text
          style={{
            fontSize: 30,
            fontWeight: "bold",
            alignSelf: "center",
            ...CSStext,

            marginVertical: "3%",
          }}
        >
          {" "}
          FAQ:
        </Text>

        <View style={styles.page}>
          <Text style={styles.titleText}>What does cointr.ee do?</Text>
          <Text style={styles.answerText}>
            Cointr.ee makes it super easy to pay someone in crypto. Add your
            public crypto addresses to your page, and you no longer have to send
            people multiple addresses for different currencies: just send a
            single cointr.ee link. You can swap out your addresses any time you
            want, and it won’t affect the cointr.ee link that you send to
            people. If you’ve posted a crypto address on your video content or
            in tweets and you no longer have access to that address, you have
            lost any money that is sent there. By posting your cointr.ee link
            instead, you are not circulating addresses that might go stale.
          </Text>

          <Text style={styles.titleText}>
            Does cointr.ee have access to my funds?
          </Text>
          <Text style={styles.answerText}>
            Cointr.ee doesn’t touch any user funds. You simply list your public
            crypto addresses on your cointr.ee page, and when people send a
            donation to those addresses, cointr.ee allows them to also send a
            message.
          </Text>

          <Text style={styles.titleText}>
            How do I make a payment on cointr.ee?
          </Text>
          <Text style={styles.answerText}>
            When you click on a currency on someone’s cointr.ee page, there are
            3 options for payment:
          </Text>
          <Text style={{ marginLeft: "10px" }}>
            <Text style={styles.answerText}>A QR code</Text>
          </Text>
          <Text style={{ marginLeft: "10px" }}>
            <Text style={styles.answerText}>
              A clickable link that will open your native crypto wallet
            </Text>
          </Text>
          <Text style={{ marginLeft: "10px" }}>
            <Text style={styles.answerText}>
              An address that can be copied to the clipboard
            </Text>
          </Text>
          <Text style={styles.answerText}>
            Choose whichever option works for you! Add in the amount you wish to
            send, then either add a message or skip straight to payment!
          </Text>

          <Text style={styles.titleText}>
            Can I use cointr.ee for things other than donations?
          </Text>
          <Text style={styles.answerText}>
            Yes! Think of it like Venmo for crypto in a way: If someone needs to
            pay you back for something, they don’t need to ask for your address,
            they can just visit your cointr.ee page. This means you don’t have
            to send people a bunch of different crypto addresses, just a single
            link, and they can choose which of your addresses to use.
          </Text>

          <Text style={styles.titleText}>
            How can I encourage more people to visit my cointr.ee?
          </Text>
          <Text style={styles.answerText}>
            Posting a link in your social media bios like Twitter and Instagram
            make it more likely that people will see your link and visit your
            page. It can be hard to support your work via community
            contributions. Just keep providing things of value to others, and
            include your cointr.ee link so that others know that you’re
            accepting donations. Perhaps you can try using cointr.ee to sell
            products instead. Play around and see what works for you!
          </Text>

          <Text style={styles.titleText}>
            I want to make an anonymous donation, is this possible?
          </Text>
          <Text style={styles.answerText}>
            You don’t have to create an account or sign in to send someone money
            on cointr.ee. You can even send an anonymous message via cointr.ee.
            Just make sure you’re logged out, and the message will appear as
            “anonymous”.
          </Text>

          <Text style={styles.titleText}>
            My message is taking longer than expected to show up.
          </Text>
          <Text style={styles.answerText}>
            Messages will arrive after a block has been confirmed, and sometimes
            it can take a few minutes for the system to recognize the block
            confirmation. If you have an issues please reach out!
          </Text>

          <Text style={styles.titleText}>
            What are the requirements for profile pictures?
          </Text>

          <Text style={styles.answerText}>
            Size limits for profile pictures are 100kb
          </Text>

          <Text style={styles.titleText}>Please list “x” coin!</Text>
          <Text style={styles.answerText}>
            You can add any coin that you’d like by using the “custom” option.
            We are working hard to add more coins to the website, so always feel
            free to make suggestions if there’s something you’d like to see!
          </Text>

          <Text style={styles.titleText}>
            Is there an option for lightning payments?
          </Text>
          <Text style={styles.answerText}>
            There are no static addresses for lightning so they can’t be listed
            under the coins section. We recommend you add your “tippin.me” link
            to the links section instead!
          </Text>

          <Text style={styles.titleText}>Please list “x” website!</Text>
          <Text style={styles.answerText}>
            You can add any URL to the website using the “custom” option. You
            can also relabel links by using the custom option instead! Let us
            know if there are any websites you think we should add to our list!
          </Text>
        </View>
      </View>
    );
  }
}

let styles = StyleSheet.create({
  container: {
    flex: 1,
    //alignItems: "center",
    alignSelf: "center",
    maxWidth: MAX_SITE_WIDTH,
  },
  page: {
    paddingHorizontal: 10,
    backgroundColor: "white",
  },
  titleText: {
    marginTop: "25px",
    marginBottom: "5px",
    fontSize: 20,
    fontWeight: "bold",
    ...CSStext,
  },
  answerText: {
    color: "darkorange",
    fontFamily: generalText,
  },
});

export default FAQ;

import React, { Component } from "react";
import { StyleSheet, Text, TextInput, View } from "react-native";
import { Button, Input } from "react-native-elements";
import firebase from "../firebase";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";

import { COLOR, generalText } from "../cssConstants";
import { MAX_SITE_WIDTH } from "../constants";

class ChangeEmail extends Component {
  state = {
    password: null,
    email: null,
  };

  _authStateLoaded = (user) => {
    this.setState({ user, email: user.email });
  };

  _submit = async () => {
    try {
      const currentUser = firebase.auth().currentUser;
      const userCredential = await firebase
        .auth()
        .signInWithEmailAndPassword(currentUser.email, this.state.password);
      await userCredential.user.updateEmail(this.state.email);
      ToastsStore.success("Email updated!");
      this.setState({ password: null });
    } catch (e) {
      ToastsStore.error(e.message);
    }
  };
  render() {
    let { email, password } = this.state;
    if (email === null) {
      firebase.auth().onAuthStateChanged(this._authStateLoaded);
    }

    return (
      <View style={styles.container}>
        <Input
          labelStyle={{ fontFamily: generalText }}
          label="Please enter your new email:"
          value={email ?? ""}
          onChange={(e) => this.setState({ email: e.target.value })}
          inputStyle={{ fontFamily: generalText }}
        ></Input>
        <Input
          labelStyle={{ paddingTop: "25px", fontFamily: generalText }}
          label="Please re-enter your password:"
          secureTextEntry={true}
          value={password ?? ""}
          onChange={(e) => this.setState({ password: e.target.value })}
          inputStyle={{ fontFamily: generalText }}
        ></Input>
        <Button
          title={"Change Email"}
          onPress={this._submit}
          titleStyle={styles.button}
          type={"clear"}
          raised={true}
        />
        <ToastsContainer
          position={ToastsContainerPosition.BOTTOM_RIGHT}
          store={ToastsStore}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: "auto",
    marginTop: "10%",
    width: "100%",
    maxWidth: MAX_SITE_WIDTH,
    justifyContent: "center",
    alignItems: "stretch",
    paddingTop: "15px",
  },
  icon: {
    left: 0,
    paddingRight: 10,
  },
  button: {
    color: COLOR.green,
    alignSelf: "stretch",
    fontFamily: generalText,
  },
});
export default ChangeEmail;

import React, { Component } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Card } from "react-native-elements";

import Username from "./InnerUsername";
import { generalText, COLOR } from "../cssConstants";
import { MAX_SITE_WIDTH } from "../constants";

class UsernameWrapper extends Component<{ loggedInUser: firebase.User }, {}> {
  render() {
    const loggedInAs = this.props.loggedInUser?.displayName;
    let title =
      "Please choose a username. This can always be changed in user settings.";
    if (~loggedInAs) {
      title = "Welcome to CoinTr.ee!" + "\n" + title;
    }
    return (
      <View style={styles.outerContainer}>
        <Card
          containerStyle={styles.card}
          titleStyle={styles.title}
          title={title}
        >
          <Username loggedInUser={this.props.loggedInUser} />
        </Card>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  outerContainer: {
    width: "100%",
    maxWidth: MAX_SITE_WIDTH,
    marginTop: "10%",
    marginHorizontal: "auto",
  },
  card: {
    borderColor: "#ffffff",
  },
  title: {
    color: COLOR.green,
    fontFamily: generalText,
  },
});
export default UsernameWrapper;

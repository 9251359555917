import queryString from 'query-string';
import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { withLocation } from "../util"; // TODO Is there an existing file for utils?

class ActionRedirect extends Component {
    render() {
      const { mode } = queryString.parse(this.props.location.search);
      let redirect = 'verify-email';
      if (mode==='resetPassword'){
        redirect = 'new-password';
      }

      return (
         <Navigate to={{
           pathname:`/a/${redirect}`,
           search: `?${this.props.location.search}`,
          }}/>
      )
    }
}

export default withLocation(ActionRedirect)

import queryString from "query-string";
import React, { Component } from "react";
import { StyleSheet, Text, TextInput, View } from "react-native";
import { Button, Input } from "react-native-elements";

import firebase from "../firebase";
import { COLOR } from "../cssConstants";
import { MAX_SITE_WIDTH } from "../constants";
import { withLocation } from "../util"; // TODO Is there an existing file for utils?

class ResetPassword extends Component {
  state = {
    password: "",
    tokenIsGood: null,
  };

  _checkToken = async (token) => {
    let tokenIsGood;
    try {
      await firebase.auth().verifyPasswordResetCode(token);
      tokenIsGood = true;
    } catch (e) {
      tokenIsGood = false;
    }
    this.setState({ tokenIsGood });
  };

  _resetPassword = async () => {
    const { oobCode } = queryString.parse(this.props.location.search);
    const { password, tokenIsGood } = this.state;
    try {
      const result = await firebase
        .auth()
        .confirmPasswordReset(oobCode, password);
      this.props.history.push(`/a/login`);
    } catch (e) {
      this.setState({ tokenIsGood: false });
      console.log(e);
    }
  };
  render() {
    const { oobCode } = queryString.parse(this.props.location.search);
    const { password, tokenIsGood } = this.state;
    if (tokenIsGood === null) {
      this._checkToken(oobCode);
    }
    return (
      <View style={styles.container}>
        {!tokenIsGood ? (
          <Text style={styles.text}>
            This token doesn't exist or has expired
          </Text>
        ) : (
          <View>
            <Input
              value={password}
              onChange={(e) => this.setState({ password: e.target.value })}
              secureTextEntry={true}
              placeholder="password"
            />
            <Button
              title="Reset Password"
              titleStyle={styles.button}
              type={"clear"}
              raised={true}
              onPress={this._resetPassword}
            />
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginTop: "10%",
    marginHorizontal: "auto",
    alignItems: "stretch",
    width: "100%",
    maxWidth: MAX_SITE_WIDTH,
  },
  text: {
    textAlign: "center",
    color: COLOR.green,
  },
  button: {
    color: COLOR.green,
    alignSelf: "stretch",
  },
  icon: {
    left: 0,
    paddingRight: 10,
  },
});

export default withLocation(ResetPassword)

import * as Linking from "expo-linking";
import queryString from "query-string";
import React, { Component } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Button, Card, Input } from "react-native-elements";
import BeatLoader from "react-spinners/BeatLoader";

import firebase from "../firebase";
import { COLOR, generalText } from "../cssConstants";
import { MAX_SITE_WIDTH } from "../constants";
import { RouteChildrenProps } from "react-router";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import { withLocation } from "../util"; // TODO Is there an existing file for utils?

class VerifyEmail extends Component<
  RouteChildrenProps & { verifyEmail: boolean },
  {
    currentUser: any;
    checkedForCurrentUser: boolean;
    message: string | null;
    email: string;
    appliedCode: boolean;
  }
> {
  state = {
    currentUser: null,
    checkedForCurrentUser: false,
    message: null,
    email: "",
    appliedCode: false,
  };
  _getCurrentUser = async () => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser?.isAnonymous) {
      this.setState({
        currentUser,
        checkedForCurrentUser: true,
      });
    }
  };
  _sendEmail = async () => {
    try {
      const { currentUser } = this.state;
      if (this.props.verifyEmail) {
        await currentUser.sendEmailVerification();
        this.setState({ message: "Sent!" });
      } else {
        const email = currentUser ? currentUser.email : this.state["email"];
        await firebase.auth().sendPasswordResetEmail(email);
        this.setState({ message: "Sent!" });
      }
    } catch (err) {
      ToastsStore.error(err.message);
    }
  };

  _applyActionCode = async (oobCode) => {
    try {
      await firebase.auth().applyActionCode(oobCode);
      Linking.openURL("/a/username");
    } catch (error) {
      ToastsStore.error(error.message);
    }
    this.setState({ appliedCode: true });
  };

  render() {
    const { oobCode } = queryString.parse(this.props.location.search);
    const checkingVerificationCode =
      oobCode !== undefined && !this.state.appliedCode;
    if (checkingVerificationCode) {
      this._applyActionCode(oobCode);
    }

    const { currentUser, checkedForCurrentUser, message } = this.state;
    const verifyEmail = this.props.verifyEmail;
    const buttonMessage = verifyEmail
      ? "Resend Verification Email."
      : "Send Password Reset Link.";
    // only look for currentUser if not verifying email.
    if (!oobCode) {
      if (!currentUser && !checkedForCurrentUser) {
        firebase.auth().onAuthStateChanged(this._getCurrentUser);
      }
    }

    let displayComponent;
    let actionComponent;
    if (checkingVerificationCode) {
      displayComponent = (
        <Text style={styles.text}>Checking verification code.</Text>
      );
    } else {
      if (currentUser) {
        displayComponent = verifyEmail ? (
          <Text style={styles.text}>
            Sent to {currentUser.email}! Please check your email.
          </Text>
        ) : (
          <Text style={styles.text}>
            Send password reset to {currentUser.email}?
          </Text>
        );
      } else {
        displayComponent = (
          <Input
            inputStyle={{ fontFamily: generalText }}
            onChange={(e) => this.setState({ email: e.target.value })}
            type="text"
            placeholder="email"
            leftIcon={{
              type: "font-awesome",
              name: "envelope",
              color: COLOR.green,
            }}
            leftIconContainerStyle={styles.icon}
          />
        );
      }

      if (message) {
        actionComponent = <Text style={styles.text}>{message}</Text>;
      } else {
        actionComponent = (
          <Button
            title={buttonMessage}
            onPress={this._sendEmail}
            titleStyle={styles.button}
            type="clear"
          />
        );
      }
    }

    return (
      <View
        style={{
          width: "100%",
          marginTop: "10%",
          marginHorizontal: "auto",
          maxWidth: MAX_SITE_WIDTH,
        }}
      >
        <Card>
          <BeatLoader
            css={"margin: 0 auto;"}
            color={COLOR.green}
            loading={checkingVerificationCode}
          />
          <ToastsContainer
            position={ToastsContainerPosition.BOTTOM_RIGHT}
            store={ToastsStore}
          />
          {displayComponent}
          {actionComponent}
        </Card>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  text: {
    textAlign: "center",
    color: COLOR.green,
    fontFamily: generalText,
  },
  errorText: {
    textAlign: "center",
    color: COLOR.error,
    fontFamily: generalText,
  },
  button: {
    color: COLOR.green,
    alignSelf: "stretch",
    fontFamily: generalText,
  },
  icon: {
    left: 0,
    paddingRight: 10,
  },
});
export default withLocation(VerifyEmail)

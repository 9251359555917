import React, { Component } from "react";
import { FlatList, StyleSheet, View } from "react-native";
import { Text } from "react-native-elements";

import Address from "./Address";
import SocialMedia from "./SocialMedia";
import AddInput from "./AddInput";
import { MAX_SITE_WIDTH } from "../constants";
import { COLOR,  generalText } from "../cssConstants";
import { RouteChildrenProps } from "react-router";
import { withLocation } from "../util"; // TODO Is there an existing file for utils?

import firebase from "../firebase";
import { withMatch } from "../util"; // TODO Is there an existing file for utils?

async function _getUser(username) {
  const user = await firebase
    .firestore()
    .collection("user")
    .doc(username.toLowerCase())
    .get();
  if (!user) {
    window.open("/a/FourZeroFour", "_self");
  }
  return user;
}
class User extends Component<
  RouteChildrenProps & {
    loggedInUser: firebase.User | null;
    userData: { addresses: any; socialMediaSites: any };
  },
  { social: any; addresses: any; hideSocial: boolean }
> {
  state = {
    social: null,
    addresses: null,
    hideSocial: false,
  };

  async getAddresses(): Promise<void> {
    const pageOwner = this.props.location.pathname.replace(/\//g, "");
    if (pageOwner) {
      const addressesDoc = await _getUser(pageOwner);
      if (!addressesDoc.data()) {
        window.open("/a/FourZeroFour", "_self");
      }
      const addresses = addressesDoc.data().addresses;
      this.setState({
        addresses: addresses || [],
      });
    }
  }

  hideSocial() {
    this.setState({ hideSocial: true });
  }
  showSocial() {
    this.setState({ hideSocial: false });
  }
  async getSocialMediaSites(): Promise<void> {
    const pageOwner = this.props.location.pathname.replace(/\//g, "");
    if (pageOwner) {
      const socialMediaSitesDoc = await _getUser(pageOwner);
      if (!socialMediaSitesDoc.data()) {
        window.open("/a/FourZeroFour", "_self");
      }
      const socialMediaSites = socialMediaSitesDoc.data().socialMediaSites;
      this.setState({ social: socialMediaSites || [] });
    }
  }

  componentDidMount() {
    const { addresses, socialMediaSites: social } = this.props.userData;
    this.setState({
      addresses,
      social,
    });
  }
  render() {
    const loggedInAs = this.props.loggedInUser?.displayName ?? "Anonymous";
    const { name } = this.props.match.params;

    const { addresses, social, hideSocial } = this.state;

    const pageOwner = this.props.location.pathname.replace("/", "");
    return (
      <View style={styles.container}>
        <View>
          <FlatList
            data={addresses /*data.userAddresses.sort((a,b)=>a.rank-b.rank)*/}
            renderItem={(address) => (
              <Address
                loggedInUser={this.props.loggedInUser}
                address={address}
                getAddresses={this.getAddresses.bind(this)}
                isOwner={loggedInAs === name}
              />
            )}
            keyExtractor={(address, index) => `${index}`}
            ListEmptyComponent={
              <Text style={styles.fadedText} h4>
                No addresses yet.
              </Text>
            }
          />
          {loggedInAs === name && (
            <View style={styles.addAddressView}>
              <AddInput
                loggedInUser={this.props.loggedInUser}
                getAddresses={this.getAddresses.bind(this)}
                version="address"
                hideSocial={this.hideSocial.bind(this)}
                showSocial={this.showSocial.bind(this)}
              />
            </View>
          )}

          {loggedInAs !== name && <View style={{ paddingVertical: 10 }}></View>}
          <FlatList
            data={social}
            renderItem={(social) => (
              <SocialMedia
                loggedInUser={this.props.loggedInUser}
                social={social}
                getSocialMediaSites={this.getSocialMediaSites.bind(this)}
                isOwner={loggedInAs === name}
              />
            )}
            keyExtractor={(social, index) => `${index}`}
          />
          {loggedInAs === name && !hideSocial && (
            <View style={styles.addAddressView}>
              <AddInput
                loggedInUser={this.props.loggedInUser}
                getSocialMediaSites={this.getSocialMediaSites.bind(this)}
                version="social"
              />
            </View>
          )}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLOR.backgroundGreen,
    alignSelf: "center",
    //alignItems: 'stretch',
    maxWidth: MAX_SITE_WIDTH,
    width: "100%",
    marginHorizontal: 3,
  },
  loader: {
    alignSelf: "center",
    justifyContent: "center",
    //width: '80%',
  },
  addAddressView: {
    marginTop: "30px",
    zIndex: 1,
  },
  addSocialView: {
    marginTop: "30px",
    zIndex: -1,
  },
  fadedText: {
    textAlign: "center",
    color: COLOR.lightGrey,
    fontFamily: generalText,
  },
});

export default withMatch(withLocation(User));

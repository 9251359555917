import React, { Component } from "react";
import { StyleSheet, Text, View } from "react-native";
import { Button, Input } from "react-native-elements";
import * as Linking from "expo-linking";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";

import { COLOR, generalText } from "../cssConstants";
import firebase from "../firebase";
import { MAX_SITE_WIDTH } from "../constants";

class Login extends Component<
  { loggedInUser: firebase.User | null; login: string },
  {
    email: string;
    password: string;
    submitted: boolean;
  }
> {
  state = {
    email: "",
    password: "",
    submitted: false,
  };

  _signIn = async () => {
    if (this.state.submitted) {
      return;
    }
    this.setState({ submitted: true });
    const { email, password } = this.state;
    try {
      const res = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      if (res.user["emailVerified"]) {
        Linking.openURL(`/${res.user["displayName"]}`);
      } else {
        Linking.openURL("/a/verify-email");
      }
    } catch (error) {
      const errorMessage = error.message;
      ToastsStore.error(errorMessage);
      this.setState({ submitted: false });
    }
  };

  _signUp = async () => {
    if (this.state.submitted) {
      return;
    }
    this.setState({ submitted: true });
    const { email, password } = this.state;
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (res) => {
        await res.user.sendEmailVerification();
        Linking.openURL("/a/verify-email");
      })
      .catch((error) => {
        const errorMessage = error.message;
        ToastsStore.error(errorMessage);
        this.setState({ submitted: false });
      });
  };

  render() {
    if (this.props.loggedInUser) {
      Linking.openURL(`/${this.props.loggedInUser.displayName}`);
    }

    const { email, password } = this.state;
    const login = this.props.login;

    return (
      <View style={styles.outerContainer}>
        <View style={styles.container}>
          <ToastsContainer
            position={ToastsContainerPosition.BOTTOM_RIGHT}
            store={ToastsStore}
          />
          <Input
            value={email}
            onChange={(e) => this.setState({ email: e.target.value })}
            placeholder="email"
            inputStyle={{ textAlign: "center", fontFamily: generalText }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                (login ? this._signIn : this._signUp)();
              }
            }}
          />
          <Input
            value={password}
            onChange={(e) => this.setState({ password: e.target.value })}
            placeholder="password"
            secureTextEntry={true}
            inputStyle={{ textAlign: "center", fontFamily: generalText }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                (login ? this._signIn : this._signUp)();
              }
            }}
          />
        </View>
        <View style={styles.buttonGroup}>
          <Button
            title={login ? "Login" : "Sign Up"}
            onPress={login ? this._signIn : this._signUp}
            titleStyle={styles.actionButtonTitle}
            buttonStyle={styles.actionButton}
            raised={true}
            loading={this.state.submitted}
          />
          <Button
            title="Forgot Password?"
            onPress={() => {
              Linking.openURL("/a/reset-password");
            }}
            titleStyle={styles.forgotPasswordButton}
            type={"clear"}
            raised={true}
          />
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  outerContainer: {
    alignSelf: "center",
    marginTop: "10%",
    width: "100%",
    maxWidth: MAX_SITE_WIDTH,
    paddingHorizontal: "3px",
  },
  container: {
    alignItems: "stretch",
  },
  buttonGroup: {
    marginTop: "10px",
  },
  actionButtonTitle: {
    color: COLOR.white,
    alignSelf: "stretch",
  },
  actionButton: {
    backgroundColor: COLOR.green,
  },
  forgotPasswordButton: {
    color: COLOR.green,
    alignSelf: "stretch",
    fontSize: "small",
  },
});
export default Login;

import React, { Component } from "react";
import PacmanLoader from "react-spinners/PacmanLoader";
import { StyleSheet, View } from "react-native";
import FlatList from "flatlist-react";
import { Text } from "react-native-elements";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";

import { COLOR, CSStext, generalText } from "../cssConstants";
import Message from "./Message";

import firebase from "../firebase";
import { MAX_SITE_WIDTH } from "../constants";
const PAGE_LENGTH = 10;

export default class MessageList extends Component<
  {
    mainFeed?: boolean;
    filterByRecipient?: string;
    ignoreMessages?: string[];
    muted: boolean;
  },
  {
    unsubscribe: () => void;
    messages: object;
    messageDict: { [keyof: string]: any };
    lastMessage: any;
    retrievedMessages: boolean;
    hasMoreMessages: boolean;
  }
> {
  state = {
    unsubscribe: () => null,
    messages: [],
    messageDict: {},
    lastMessage: null,
    retrievedMessages: false,
    hasMoreMessages: false,
  };

  async getMessages(newMessage?: any) {
    let messagesDoc;
    if (!newMessage) {
      let query = firebase
        .firestore()
        .collection("messages")
        .where("public", "==", true)
        .orderBy("created", "desc");
      let filterByRecipient = this.props.filterByRecipient;
      if (!filterByRecipient && !this.props.mainFeed) {
        return;
      }
      if (filterByRecipient) {
        const userDoc = await firebase
          .firestore()
          .collection("user")
          .doc(filterByRecipient)
          .get();
        const filterByRecipientUid = userDoc.data().uid;
        query = query.where("uidRecipient", "==", filterByRecipientUid);
      }

      if (this.state.lastMessage) {
        query = query.startAfter(this.state.lastMessage).limit(PAGE_LENGTH);
      } else {
        query = query.limit(20);
      }

      messagesDoc = await query.get();
    } else {
      messagesDoc = { docs: [newMessage] };
    }

    let newMessages = {};
    messagesDoc.docs.forEach((d) => {
      if (!(this.props.ignoreMessages ?? []).includes(d.id)) {
        newMessages[d.id] = d.data();
      }
    });

    if (newMessage) {
      this.setState({
        messageDict: {
          ...this.state.messageDict,
          ...newMessages,
        },
      });
    } else {
      this.setState({
        hasMoreMessages: Object.keys(newMessages).length >= PAGE_LENGTH,
        lastMessage: messagesDoc.docs.slice(-1)[0],
        messageDict: {
          ...this.state.messageDict,
          ...newMessages,
        },
        retrievedMessages: true,
      });
    }
  }

  renderMessage(message) {
    return <Message messageData={{ item: message }} key={message.key} />;
  }

  async componentDidUpdate() {
    if (!this.state.retrievedMessages) {
      this.getMessages();
    }
    //get more messages if screen isn't filled up yet
    if (
      this.state.hasMoreMessages &&
      this.state.messages.length < PAGE_LENGTH
    ) {
      this.getMessages();
    }
  }

  async componentDidMount() {
    const messageListObject = this;
    const unsubscribe = await firebase
      .firestore()
      .collection("messages")
      .where("public", "==", true)
      .orderBy("created", "desc")
      .limit(50)
      .onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(async function (change) {
          if (change.type === "added") {
          }
          if (change.type === "modified") {
            if (
              messageListObject.props.filterByRecipient &&
              messageListObject.props.filterByRecipient !==
                change.doc.data()["nameRecipient"]
            ) {
              // ignore new messages if not for user
              return;
            }
            if (change.doc.data()["confirmations"] === 0) {
              ToastsStore.success("New Message! 🎉");
              if (!messageListObject.props.muted) {
                const sound = new Audio(
                  require(`../../assets/sounds/notification.mp3`)
                );
                sound.play();
              }
            } else if (change.doc.data()["confirmations"] > 0) {
              ToastsStore.success("Message Confirmed! 🔒");
            }
            messageListObject.getMessages(change.doc);
          }
          if (change.type === "removed") {
          }
        });
      });
    this.setState({ unsubscribe });
  }
  async componentWillUnmount() {
    await this.state.unsubscribe();
  }
  render() {
    const { messageDict } = this.state;
    const messages = Object.keys(messageDict)
      .map((key) => {
        if (parseInt(messageDict[key]["confirmations"]) > -1) {
          return { id: key, ...messageDict[key] };
        }
      })
      .filter((m) => m)
      .sort((a, b) => {
        return b["created"]["seconds"] - a["created"]["seconds"];
      });
    return (
      <View style={styles.listContainer}>
        {this.state.retrievedMessages ? (
          <View>
            <FlatList
              list={messages.map((m) => {
                return { key: m.id, ...m };
              })}
              renderItem={this.renderMessage.bind(this)}
              hasMoreItems={this.state.hasMoreMessages}
              loadMoreItems={this.getMessages.bind(this)}
              renderWhenEmpty={() => {
                return (
                  <Text style={styles.fadedText} h4>
                    No messages yet.
                  </Text>
                );
              }}
              paginationLoadingIndicator={
                <PacmanLoader color={COLOR.green} loading={true} />
              }
              paginationLoadingIndicatorPosition="center"
              canCancelContentTouches={true}
            />

            <ToastsContainer
              position={ToastsContainerPosition.BOTTOM_RIGHT}
              store={ToastsStore}
            />
          </View>
        ) : (
          <View style={styles.loader}>
            <PacmanLoader color={COLOR.green} loading={true} />
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLOR.backgroundGreen,
    alignSelf: "center",
    width: "100%",
  },
  addAddressView: {
    marginTop: "30px",
    zIndex: 1,
  },
  addSocialView: {
    marginTop: "30px",
    zIndex: -1,
  },
  text: {
    ...CSStext,
    paddingVertical: 20,
    fontStyle: "italic",
    alignSelf: "center",
  },
  fadedText: {
    marginTop: "15%",
    textAlign: "center",
    color: COLOR.lightGrey,
    fontFamily: generalText,
  },
  listContainer: {
    alignSelf: "center",
    width: "100%",
    maxWidth: MAX_SITE_WIDTH,
    flex: 1,
    height: "100%",
  },
  loader: {
    marginTop: "15%",
    alignSelf: "center",
    justifyContent: "center",
  },
});

import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
  ActivityIndicator,
  Dimensions,
  View,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
} from "react-native";
import Constants from "expo-constants";
import firebase from "../firebase";
import Collapsible from "react-native-collapsible";
import { ToastsContainer, ToastsStore } from "react-toasts";

import { COLOR, generalText } from "../cssConstants";

const createDraft = firebase.functions().httpsCallable("createDraft");

const defaultState = {
  sent: false,
  activeSections: [],
  collapsed: true,
  text: "",
  usdAmount: "10",
  cryptoAmount: null,
  public: true,
};

export default class MessageForm extends Component {
  state = { ...defaultState };

  onChangeUSD(usdAmount) {
    this.setState({ usdAmount });
  }
  onChangeText(text) {
    this.setState({ text });
  }

  handleMessageUpdate(doc) {
    if (doc.get("confirmations") >= 0) {
      this.props.hideModal();
      ToastsStore.success("Transaction complete!");
    }
  }
  async submitDraft(): Promise<void> {
    this.setState({ sent: true });
    if (!firebase.auth().currentUser) {
      await firebase.auth().signInAnonymously();
    }
    const createDraftResult = await createDraft({
      text: this.state.text,
      fiatAmount: this.state.usdAmount,
      address: this.props.address,
      cryptoCurrency: this.props.currency.toUpperCase(),
      toUsername: window.location.pathname.replace("/", ""),
      public: this.state.public,
    });
    if (createDraftResult.data.error) {
      ToastsStore.error(createDraftResult.data.error);
      throw Error(createDraftResult.data.error);
    }
    try {
      // enable when we want public messages or something
      firebase
        .firestore()
        .collection("messages")
        .doc(createDraftResult.data.id)
        .onSnapshot(this.handleMessageUpdate.bind(this));
    } catch (e) {
      console.log("listen attempt");
      console.log({ e });
    }
    const { cryptoAmount } = createDraftResult.data;
    this.setState({
      cryptoAmount,
      sent: false,
    });
    this.props.setAmount(cryptoAmount);
    this.setState(defaultState);
  }

  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  async action() {
    try {
      await this.submitDraft();
      this.props.renderModal();
    } finally {
      this.setState({
        sent: false,
      });
    }
  }

  render() {
    const windowDimension = Dimensions.get("window");
    const qrEdge = Math.floor(
      Math.min(windowDimension.height, windowDimension.width) / 2
    );
    const uri_address = "bitcoin:1F1tAaz5x1HUXrCNLbtMDqcw6o5GNn4xqX";

    return (
      <View style={styles.container}>
        {/* Message */}
        <Collapsible collapsed={!this.props.collapsed} align="center">
          <View style={styles.content}>
            <TextInput
              style={styles.inputs}
              value={this.state.text}
              onChangeText={(text) => this.onChangeText(text)}
              multiline
              numberOfLines={5}
              placeholder="Attach a message, or proceed to payment!"
            />
          </View>

          {/* Amount */}
          <View style={styles.inlineView}>
            <Text style={styles.headerText}>$</Text>
            <TextInput
              style={styles.inputs}
              value={this.state.usdAmount}
              onChangeText={(USD) => this.onChangeUSD(USD)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={!this.state.public}
                  onChange={() => {
                    this.setState({ public: !this.state.public });
                  }}
                  style={{ marginRight: 5 }}
                  color="default"
                />
              }
              label="Private:"
              labelPlacement="start"
            />
          </View>
          {this.state.sent ? (
            <ActivityIndicator size="large" color={COLOR.green} />
          ) : (
            <TouchableOpacity
              style={styles.button}
              onPress={this.action.bind(this)}
            >
              <Text style={styles.buttonText}>Proceed to payment!</Text>
            </TouchableOpacity>
          )}
        </Collapsible>

        <ToastsContainer store={ToastsStore} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLOR.backgroundGreen,
    paddingTop: Constants.statusBarHeight,
    paddingHorizontal: 10,
  },
  button: {
    alignItems: "center",
    backgroundColor: "#FFDF00",
    padding: 10,
  },
  buttonText: {
    color: COLOR.white,
    fontFamily: generalText,
  },
  headerText: {
    width: "60px",
    padding: 10,
    fontSize: 20,
    fontWeight: "500",
    textAlign: "center",
    backgroundColor: COLOR.lightGrey,
    fontFamily: generalText,
  },
  inlineView: {
    marginVertical: 5,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  content: {
    flexDirection: "row",
    padding: 20,
    backgroundColor: "#fff",
    justifyContent: "center",
  },
  inputs: {
    //backgroundColor: COLOR.lightGrey,
    //borderColor: COLOR.lightGrey,
    width: "100%",
    padding: 10,
    fontSize: 20,
    textAlign: "left",
    backgroundColor: COLOR.white,
    fontFamily: generalText,
    //borderWidth: 1,
  },
});

import React, { Component } from "react";
import { View, StyleSheet, Linking } from "react-native";
import { Card, Button } from "react-native-elements";

import { COLOR, generalText } from "../cssConstants";
import Username from "./InnerUsername";
import MinimumSend from "./MinimumSend";
import firebase from "../firebase";
import { MAX_SITE_WIDTH } from "../constants";
import _ from "lodash";

class UserSettings extends Component<
  { loggedInUser: firebase.User },
  { userData: any }
> {
  state = { userData: undefined };
  _signOut = () => {
    firebase
      .auth()
      .signOut()
      .then((res) => {
        localStorage.removeItem("cointree:username");
        localStorage.removeItem("cointree:uid");
        Linking.openURL("/");
      });
  };
  async componentDidUpdate() {
    const username = this.props.loggedInUser?.displayName.toLowerCase();
    if (!username) {
      return;
    }
    const userDoc = await firebase
      .firestore()
      .collection("user")
      .doc(username)
      .get();
    const userData = userDoc.data();

    if (!_.isEqual(userData, this.state.userData)) {
      this.setState({ userData });
    }
  }
  render() {
    //const loggedInAs = localStorage.getItem("cointree:username");
    const loggedInAs = this.props.loggedInUser?.displayName;
    if (!loggedInAs) {
      //this._signOut();
    }
    return (
      <View style={styles.container}>
        <Card>
          <Button
            title={`Profile (${loggedInAs})`}
            onPress={() => {
              Linking.openURL(`/${loggedInAs}`);
            }}
            type="clear"
            titleStyle={styles.button}
            raise={true}
          />
          <Username loggedInUser={this.props.loggedInUser} />
          <Button
            title="change email"
            onPress={() => {
              Linking.openURL("/a/change-email");
            }}
            type="clear"
            titleStyle={styles.button}
            raise={true}
          />
          <MinimumSend
            loggedInUser={this.props.loggedInUser}
            amount={this.state.userData?.preferences?.sendAmount.minimum}
          />
          <Button
            title="reset password"
            onPress={() => {
              Linking.openURL("/a/reset-password");
            }}
            type="clear"
            titleStyle={styles.button}
            raise={true}
          />
          <Button
            title="logout"
            onPress={this._signOut}
            titleStyle={styles.button}
            type="clear"
            raise={true}
          />
        </Card>
      </View>
    );
  }
}

let styles = StyleSheet.create({
  container: {
    width: "100%",
    maxWidth: MAX_SITE_WIDTH,
    marginTop: "10%",
    marginHorizontal: "auto",
  },
  button: {
    color: COLOR.green,
    fontFamily: generalText,
  },
});

export default UserSettings;

import { registerRootComponent } from "expo";
import React from "react";
import { StyleSheet, View, Linking } from "react-native";
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LinearGradient } from "expo-linear-gradient";

import ActionRedirect from "./components/ActionRedirect";
import Landing from "./components/Landing";
import Login from "./components/Login";
import Header from "./components/Header";
import UserSettings from "./components/UserSettings";
import VerifyEmail from "./components/VerifyEmail";
import ResetPassword from "./components/ResetPassword";
import Username from "./components/Username";
import MessageForm from "./components/MessageForm";
import UserTabs from "./components/UserTabs";
import DisplayMessage from "./components/DisplayMessage";
import FourZeroFour from "./components/FourZeroFour";
import ChangeEmail from "./components/ChangeEmail";
import FAQ from "./components/FAQ";
import Search from "./components/Search";

import Modal from "./components/Modal";
import { COLOR } from "./cssConstants";
import WebFont from "webfontloader";
import firebase from "./firebase";

WebFont.load({
  google: {
    families: ["Mulish", "Lobster"],
  },
});
export default class App extends React.Component<
  {},
  { loggedInUser: firebase.User | null }
> {
  state = {
    loggedInUser: null,
  };
  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        return;
      }
      const { pathname } = new URL(window.location.href);
      if (user["emailVerified"]) {
        const username = user["displayName"];
        if (!username && pathname !== "/a/username") {
          Linking.openURL("/a/username");
        }
      } else if (!user.isAnonymous) {
        if (
          !["/", "/a/verify-email", "/a/login", "/a/settings"].includes(
            pathname
          )
        ) {
          Linking.openURL("/a/verify-email");
        }
      }
      this.setState({ loggedInUser: user });
    });
  }
  render() {
    return (
      <SafeAreaProvider>
        <BrowserRouter>
          <View style={styles.outerContainer}>
            <Header loggedInUser={this.state.loggedInUser} />
            <View style={styles.backGroundColor}>
              <LinearGradient
                // Background Linear Gradient
                colors={["#f5fff5", "transparent"]}
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  height: 300,
                }}
              >
                <LinearGradient
                  // Background Linear Gradient
                  colors={["grey", "transparent"]}
                  style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    height: 1,
                  }}
                />
              </LinearGradient>
              <Routes style={styles.container}>
                <Route
                  path="/a/login"
                  element={
                    <Login
                      loggedInUser={this.state.loggedInUser}
                      login={true}
                    />
                  }
                />
                <Route
                  path="/a/signup"
                  element={<Login login={false} />}
                />
                <Route
                  path="/a/settings"
                  element={
                    <UserSettings
                      loggedInUser={this.state.loggedInUser}
                    />
                  }
                />
                <Route
                  path="/a/username"
                  element={
                    <Username loggedInUser={this.state.loggedInUser} />
                  }
                />
                <Route
                  path="/a/faq"
                  element={<FAQ />}
                />
                <Route
                  path="/a/search"
                  element={<Search/>}
                />
                <Route
                  path="/a/action"
                  element={<ActionRedirect/>}
                />
                <Route
                  path="/a/reset-password"
                  element={
                    <VerifyEmail verifyEmail={false} />
                  }
                />
                <Route
                  path="/a/new-password"
                  element={<ResetPassword />}
                />
                <Route
                  path="/a/verify-email"
                  element={
                    <VerifyEmail verifyEmail={true} />
                  }
                />
                <Route
                  path="/a/change-email"
                  element={<ChangeEmail />}
                />
                <Route
                  path="/a/message-form"
                  element={<MessageForm />}
                />
                <Route
                  path="/a/message/:messageId"
                  element={<DisplayMessage />}
                />
                <Route
                  path="/a/FourZeroFour"
                  element={<FourZeroFour />}
                />
    
                <Route
                  path="/:name"
                  element={
                    <UserTabs loggedInUser={this.state.loggedInUser} />
                  }
                />
                <Route
                  path="/"
                  element={<Landing login={false} />}
                />
              </Routes>
            </View>
          </View>
        </BrowserRouter>
      </SafeAreaProvider>
    );
  }
}

const styles = StyleSheet.create({
  outerContainer: {
    height: "100%",
  },
  container: {
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  backGroundColor: {
    backgroundColor: COLOR.backgroundGreen,
    height: "100%",
  },
});

registerRootComponent(App);
